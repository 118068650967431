import { UrlParams } from 'src/app/_shared/interface/urlParams.interface';

export function urlToUrlParams(returnUrl: string, defaultUrlParams?: UrlParams): UrlParams {
  let urlParams = defaultUrlParams;
  if (
    returnUrl &&
    returnUrl.indexOf('event-graph') === -1 &&
    returnUrl.indexOf('charts') === -1 &&
    returnUrl.indexOf('?') !== -1
  ) {
    const items = parseQueryParams(returnUrl);
    urlParams = {
      mpId: items.find((item) => item.name === 'mpId')?.value,
      account: items.find((item) => item.name === 'account')?.value,
    };
  } else if (
    returnUrl &&
    (returnUrl.indexOf('event-graph') !== -1 || (returnUrl.indexOf('charts') !== -1 && returnUrl.split('/').length > 2))
  ) {
    const items = returnUrl.split('/');
    urlParams = {
      mpId: items[2],
      account: items[3],
    };
  } else if (returnUrl && returnUrl.indexOf('monthly-report') !== -1) {
    let param = returnUrl.split('/');
    urlParams = { mpId: param[3], account: param[2] };
  } else if (returnUrl && returnUrl.indexOf('monthly') !== -1) {
    let param = returnUrl.split('/');
    urlParams = { mpId: param[4], account: param[3] };
  }

  return urlParams;
}

function parseQueryParams(url: string): { name: string; value: string }[] {
  if (!url.includes('?')) return [];
  return url
    .split('?')[1]
    .split('&')
    .map((items) => {
      const queryItems = items.split('=');
      if (queryItems.length === 2) {
        return { name: queryItems[0], value: queryItems[1] };
      }
      return null;
    })
    .filter((item) => item !== null);
}
