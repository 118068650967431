import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'DST',
})
export class DSTPipe implements PipeTransform {
  transform(value: string, ...args: any[]): any {
    if (value) {
      let momentValue = moment(value);
      if (args[1]) {
        momentValue = momentValue.tz(args[1]);
      }

      switch (args[0]) {
        case 'LTSS':
          // time with milliseconds (6:46:00 .000 PM)
          return momentValue.format(moment().creationData().locale.longDateFormat('LTS').replace('ss', 'ss .SSS'));
        case 'LLT':
          // date local format + time (10/30/2024 6:46 PM)
          return `${momentValue.format(moment().creationData().locale.longDateFormat('L'))} ${momentValue.format(
            moment().creationData().locale.longDateFormat('LT')
          )}`;
        case 'LLTS':
          // date local format + time with seconds (10/30/2024 6:46:00 PM)
          return `${momentValue.format(moment().creationData().locale.longDateFormat('L'))} ${momentValue.format(
            moment().creationData().locale.longDateFormat('LTS')
          )}`;
        case 'LLTSYS':
          //  date local format + time (10/30/24 6:46 PM)
          return `${momentValue.format(
            moment().creationData().locale.longDateFormat('L').replace('YYYY', 'YY')
          )} ${momentValue.format(moment().creationData().locale.longDateFormat('LT'))}`;
        default:
          break;
      }

      return momentValue.format(args[0]);
    }
  }
}
