import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCommonModule } from '@angular/material/core';
import { DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';
import { FormsModule } from '@angular/forms';

import { DatetimeRangePickerModule } from 'src/app/_shared/components/datetime-range-picker/datetime-range-picker.module';
import { EventLegendComponent } from 'src/app/_shared/components/event-legend/event-legend.component';
import { MatModule } from 'src/app/_shared/mat.module';
import { SharedModule } from 'src/app/_shared/shared.module';
import { DChartNavigatorComponent } from 'src/app/_shared/components/date-picker-nav/d-chart-navigator/d-chart-navigator.component';
import { DatePickerNavComponent } from 'src/app/_shared/components/date-picker-nav/date-picker-nav.component';
import { MatInfoDataComponent } from 'src/app/_shared/components/mat-info-data/mat-info-data.component';
import { LoginBoxComponent } from 'src/app/_shared/components/login-box/login-box.component';
import { PhasorsInfoComponent } from 'src/app/_shared/components/phasors-info/phasors-info.component';
import { HintBoxComponent } from 'src/app/_shared/components/hint-box/hint-box.component';
import { NewNoteComponent } from 'src/app/_shared/components/new-note/new-note.component';
import { SiteMpDialogModule } from 'src/app/_shared/components/site-mp-dialog/site-mp-dialog.module';
import { DragDropZoneModule } from 'src/app/_shared/components/drag-drop-zone/drag-drop-zone.module';
import { LoaderComponent } from 'src/app/_shared/components/loader/loader.component';
import { DeleteNoteDialogComponent } from 'src/app/_shared/components/delete-note-dialog/delete-note-dialog.component';
import { MatSelectSearchComponent } from 'src/app/_shared/components/mat-select-search/mat-select-search.component';
import { DeleteMemberDialogComponent } from 'src/app/_shared/components/delete-member-dialog/delete-member-dialog.component';
import { RoleDescriptionComponent } from 'src/app/_shared/components/role-description/role-description.component';
import { NoDataComponent } from 'src/app/_shared/components/no-data/no-data.component';
import { RangeSelectionComponent } from 'src/app/_shared/components/range-selection/range-selection.component';
import { PeriodNavigatorComponent } from 'src/app/_shared/components/range-selection/period-navigator/period-navigator.component';
import { DisplayImageComponent } from 'src/app/_shared/components/display-image/display-image.component';
import { FilterMenuComponent } from 'src/app/_shared/components/filter-menu/filter-menu.component';
import { ListLayoutComponent } from 'src/app/_shared/components/list-layout/list-layout.component';
import { SelectMpComponent } from 'src/app/_shared/components/select-mp/select-mp.component';
import { TableDisplaySettingComponent } from 'src/app/_shared/components/table-display-setting/table-display-setting.component';

@NgModule({
  imports: [
    FormsModule,
    MatModule,
    SharedModule,
    CommonModule,
    MatButtonModule,
    OverlayModule,
    A11yModule,
    PortalModule,
    MatCommonModule,
    DatetimeRangePickerModule,
    SiteMpDialogModule,
    DragDropZoneModule,
  ],
  exports: [
    DatetimeRangePickerModule,
    EventLegendComponent,
    DatePickerNavComponent,
    RangeSelectionComponent,
    PeriodNavigatorComponent,
    DChartNavigatorComponent,
    MatInfoDataComponent,
    LoginBoxComponent,
    PhasorsInfoComponent,
    HintBoxComponent,
    NewNoteComponent,
    LoaderComponent,
    DeleteNoteDialogComponent,
    MatSelectSearchComponent,
    LoaderComponent,
    DeleteMemberDialogComponent,
    RoleDescriptionComponent,
    NoDataComponent,
    DisplayImageComponent,
    FilterMenuComponent,
    ListLayoutComponent,
    TableDisplaySettingComponent,
    SelectMpComponent,
  ],
  declarations: [
    EventLegendComponent,
    DatePickerNavComponent,
    DChartNavigatorComponent,
    RangeSelectionComponent,
    PeriodNavigatorComponent,
    MatInfoDataComponent,
    LoginBoxComponent,
    PhasorsInfoComponent,
    HintBoxComponent,
    NewNoteComponent,
    LoaderComponent,
    DeleteNoteDialogComponent,
    MatSelectSearchComponent,
    LoaderComponent,
    DeleteMemberDialogComponent,
    RoleDescriptionComponent,
    NoDataComponent,
    DisplayImageComponent,
    FilterMenuComponent,
    ListLayoutComponent,
    TableDisplaySettingComponent,
    SelectMpComponent,
  ],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
  ],
})
export class SharedComponentModule {}
