@if (!smallScreen) {
<div class="fleet-fields-wrapper">
  <label><mat-icon class="magnify-icon" svgIcon="magnify"></mat-icon></label>
  <ng-container *ngTemplateOutlet="filters"></ng-container>
</div>
} @if (smallScreen) {
<mat-expansion-panel class="fields-panel" (afterExpand)="expand($event)" #comboFilter
  ><mat-expansion-panel-header data-cy="mobile-filter-header">
    <mat-panel-title>{{ 'fleet.filter' | translate }}</mat-panel-title>
  </mat-expansion-panel-header>
  <ng-container *ngTemplateOutlet="filters"></ng-container
></mat-expansion-panel>
}
<ng-template #filters data-cy="mobile-filter-panel">
  @if (search) {
  <mat-form-field class="filter">
    <mat-label>{{ 'global.search' | translate }}</mat-label>
    <input
      matInput
      [(ngModel)]="searchFilter"
      [disabled]="disable"
      (ngModelChange)="searchFilterChange.emit($event)"
      data-cy="search-filter"
    />
  </mat-form-field>
  } @if (state) {
  <mat-form-field class="filter">
    <mat-label>{{ 'fleet.table.stage' | translate }}</mat-label>
    <mat-select
      [ngModel]="stateFilter"
      ngDefaultControl
      [compareWith]="compareStates"
      (selectionChange)="stateFilterChange.emit($event.value)"
      multiple
    >
      @for (state of stateOptions; track state) {
      <mat-option [value]="state">
        {{ state.name }}
      </mat-option>
      }
    </mat-select>
  </mat-form-field>
  } @if (alarm) {
  <mat-form-field class="filter">
    <mat-label>{{ 'fleet.table.alertEvents' | translate }}</mat-label>
    <mat-select
      [ngModel]="alarmFilter"
      ngDefaultControl
      (selectionChange)="alarmFilterChange.emit($event.value)"
      multiple
    >
      @for (alarm of alarmOptions; track alarm) {
      <mat-option [value]="alarm.value">
        {{ alarm.name }}
      </mat-option>
      }
    </mat-select>
  </mat-form-field>
  } @if (comms) {
  <mat-form-field class="filter">
    <mat-label>{{ 'fleet.table.comms.onOffline' | translate }}</mat-label>
    <mat-select
      [ngModel]="commsFilter"
      ngDefaultControl
      (selectionChange)="commsFilterChange.emit($event.value)"
      multiple
    >
      @for (comms of commsOptions; track comms) {
      <mat-option [value]="comms.value">
        {{ comms.name }}
      </mat-option>
      }
    </mat-select>
  </mat-form-field>
  } @if (status) {
  <mat-form-field class="filter">
    <mat-label>{{ 'fleet.table.status' | translate }}</mat-label>
    <mat-select
      [ngModel]="statusFilter"
      ngDefaultControl
      [compareWith]="compareStatus"
      (selectionChange)="statusFilterChange.emit($event.value)"
      multiple
    >
      @for (status of statusOptions; track status) {
      <mat-option [value]="status">
        {{ status.name }}
      </mat-option>
      }
    </mat-select>
  </mat-form-field>
  }@if (clearButton) {
  <button data-cy="clear-filters" class="clear-button" mat-icon-button (click)="clearFilters.emit(true)">
    <mat-icon svgIcon="filter-remove-outline" color="accent"></mat-icon>
  </button>
  }
</ng-template>
