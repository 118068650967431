@if (!hide){
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="overlayOrigin"
  [cdkConnectedOverlayOpen]="isOpened"
  (positionChange)="onPositionChange($event)"
  (detach)="connectedOverlayDetach()"
>
  <div
    #dialog
    role="dialog"
    aria-label="Dialog"
    [ngClass]="{
      'create-mp-dialog': hint === 'createMp',
      'live-meter-dialog': hint === 'liveMeter',
      'associate-dialog': hint === 'associate',
      'sag-direction-dialog': hint === 'sagDirection',
      'notification-center-dialog': hint === 'notificationCenter',
      'firmware-update-dialog': hint === 'firmwareUpdate',
      'firmware-update-dialog-mobile': hint === 'firmwareAndNotification',
      'range-selection-dialog': hint === 'rangeSelection',
      'report-writer-dialog': hint === 'reportWriter',
      'cdk-start': cdkPositionX === 'start',
      'cdk-end': cdkPositionX !== 'start',
    }"
    class="dialog-container"
  >
    <ng-content></ng-content>
    <mat-checkbox data-cy="close-hint" color="primary" class="dont-show-checkbox" (change)="hideMsg()">{{
      'hint.dontShow' | translate
    }}</mat-checkbox>
  </div>
  <div
    class="link-to-button"
    [ngClass]="
      hint === 'createMp'
        ? 'create-mp'
        : hint === 'notificationCenter'
        ? 'under notification-center-dialog'
        : hint === 'firmwareUpdate'
        ? 'under firmware-update-dialog'
        : hint === 'firmwareAndNotification' && cdkPositionX !== 'start'
        ? 'under firmware-update-dialog-mobile cdk-end'
        : hint === 'firmwareAndNotification' && cdkPositionX === 'start'
        ? 'under firmware-update-dialog-mobile cdk-start'
        : hint === 'associate'
        ? 'associate under'
        : hint === 'liveMeter'
        ? 'live-meter under'
        : hint === 'sagDirection'
        ? 'sag-direction under'
        : hint === 'reportWriter'
        ? 'report-writer'
        : hint === 'rangeSelection'
        ? 'under range-selection'
        : ''
    "
  ></div>
</ng-template>
}
