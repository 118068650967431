import { Observable, of, throwError, forkJoin } from 'rxjs';
import { Moment } from 'moment';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take, map, switchMap, tap, catchError, skipWhile } from 'rxjs/operators';
import { SortModelItem } from 'ag-grid-community';
import { fetchEventSource, EventSourceMessage } from '@microsoft/fetch-event-source';
import { SortDirection } from '@angular/material/sort';

import packageJson from 'package.json';
import { IUserUpdateParams, IUserUpdateResponse } from 'src/app/_shared/classes/userUpdateParams.interface';
import { IUser, ILoginResponse } from 'src/app/_shared/classes/user';
import { environment } from 'src/environments/environment';
import { TokenService } from 'src/app/_shared/services/token.service';
import { IMeasurementPoint } from 'src/app/_shared/classes/measurementpoint.interface';
import { INote } from 'src/app/_shared/classes/note.interface';
import { IEnergyMeasure } from 'src/app/_shared/classes/energyMeasure.interface';
import { IMonthlyReport } from 'src/app/_shared/classes/monthly-report.interface';
import { IAccount } from 'src/app/_shared/classes/account';
import { ISiteMp } from 'src/app/_shared/classes/SiteMp.interface';
import { DrivescanDashboard } from 'src/app/_shared/interface/drivescan/drivescan-dashboard.interface';
import { EventCount } from 'src/app/_shared/interface/events/event-count.interface';
import { DrivescanValue } from 'src/app/_shared/interface/drivescan/drivescan-value.interface';
import { DrivescanInfo } from 'src/app/_shared/interface/drivescan/drivescan-info.interface';
import { DrivescanParameters } from 'src/app/_shared/interface/drivescan/drivescan-parameters.interface';
import {
  AlarmEventNoteRequest,
  AlarmEventNoteResponse,
} from 'src/app/_shared/interface/alarm/alarm-event-note-request.interface';
import { ChannelObject } from 'src/app/_shared/interface/channel/channel-object.interface';
import { Alarm } from 'src/app/_shared/interface/alarm/alarm.interface';
import { TrendAlarm } from 'src/app/_shared/interface/alarm/trend-alarm.interface';
import { ClearAlarmReturn } from 'src/app/_shared/interface/alarm/clear-alarm-return.interface';
import { UserPreferences } from 'src/app/_shared/interface/user-preferences.interface';
import { MessageReturn } from 'src/app/_shared/interface/message-return.interface';
import {
  RecommendationCount,
  IRecommendation,
  RecommendationComment,
  Comment,
  SendRecommendationResponse,
} from 'src/app/_shared/interface/recommendation.interface';
import { EventFilterType } from 'src/app/_shared/interface/events/event-filter-type.interface';
import { FilterTypeRequest } from 'src/app/_shared/interface/filter-type-request.interface';
import { TrendAlarmModel, TrendAlarmModelQuery } from 'src/app/_shared/interface/trend-alarm-model.interface';
import { MpParameters } from 'src/app/_shared/interface/mp-parameter';
import {
  FirmwareFleetStatus,
  FirmwareFleetStatusQuery,
} from 'src/app/_shared/interface/firmware-update/firmware-fleet-status.interface';
import { PaginatedModel, Pagination, PaginationStringSort } from 'src/app/_shared/interface/pagination.interface';
import { DriveFaultState } from 'src/app/_shared/interface/drivescan/drive-fault-code.interface';
import { FirmwareVersion } from 'src/app/_shared/interface/firmware-update/firmware-version.interface';
import { MaintenanceCommand, MaintenanceRequest, MaintenanceRequestState } from 'src/app/_shared/interface/maintenance';
import { EventFile } from 'src/app/_shared/interface/events/event-files.interface';
import { Kpi } from 'src/app/_shared/interface/kpi.interface';
import { IMember, IMemberQuery } from 'src/app/_shared/classes/member.interface';
import { TrendsRequest } from 'src/app/_shared/interface/trends-request.interface';
import { Loads } from 'src/app/_shared/interface/loads.interface';
import { News } from 'src/app/_shared/interface/news.interface';
import { EventViewer } from 'src/app/_shared/interface/events/event-viewer.interface';
import { MoveToList } from 'src/app/_shared/interface/move-to-list.interface';
import { ReplacementStep } from 'src/app/_shared/interface/replacement-step.interface';
import { TrendData } from 'src/app/_shared/interface/trend-data.interface';
import { SubscriptionData, parseSubscriptionData } from 'src/app/_shared/interface/subscription-data.interface';
import { ReportWriterResult } from 'src/app/_shared/interface/report-writer/report-writer-result.interface';
import { ReportWriterHistoryRequest } from 'src/app/_shared/interface/report-writer/report-writer-history-request.interface';
import { ReportWriterTemplateResult } from 'src/app/_shared/interface/report-writer/report-writer-template-result.interface';
import { ReportWriterTemplatesRequest } from 'src/app/_shared/interface/report-writer/report-writer-templates-request.interface';
import { ReportWriterSetup } from 'src/app/_shared/interface/report-writer/report-writer-setup.interface';
import { ReportScheduleId } from 'src/app/_shared/interface/report-writer/report-setup-id.interface';
import { StatusReturn } from 'src/app/_shared/interface/status-return.interface';
import { ReportWriterCreateResult } from 'src/app/_shared/interface/report-writer/report-writer-create.result.interface';
import { ReportWriterCreateRequest } from 'src/app/_shared/interface/report-writer/report-writer-create-request.interface';
import { EditReport } from 'src/app/_shared/interface/report-writer/edit-report.interface';
import { ReportWriterFilter } from 'src/app/_shared/interface/report-writer/report-writer-filter.interface';
import { FirmwareFleet } from 'src/app/_shared/interface/firmware-update/firmware-fleet.interface';
import {
  NotificationGroup,
  NotificationGroupList,
} from 'src/app/_shared/interface/notification-group/notification-group.interface';
import { NotificationGroupId } from 'src/app/_shared/interface/notification-group/notification-group-id.interface';
import { CreateGroupParams } from 'src/app/_shared/interface/notification-group/create-group-params.interface';
import { MaxLoadCurrentDemand } from 'src/app/_shared/interface/max-load-current-demand';
import { NotificationEventGroup } from 'src/app/_shared/interface/notification-group/notification-group-event';
import { Heartbeat } from 'src/app/_shared/interface/drivescan/heartbeat.interface';
import { Features, SupportedFeaturesOnDemand } from 'src/app/_shared/classes/features';
import { UserAccess } from 'src/app/_shared/interface/users.interface';
import { MeasurementPointAccess } from 'src/app/_shared/interface/measurement-point.interface';
import { ActivityLog } from 'src/app/_shared/classes/activityLog.interface';
import {
  CreateRmsVariationAnalysisParams,
  CreateRmsVariationAnalysisResponse,
  UpdateRmsVariationAnalysisResponse,
  RmsVariationAnalysis,
  EventMagnitudeDist,
  EventDurationDist,
  RmsVariationEvent,
  IticChartPlot,
} from 'src/app/_shared/interface/rms-variation-analysis/rms-variation-analysis.interface';

@Injectable()
export class DiligentApiService {
  get baseUrl(): string {
    return environment.psl.diligent_endpoint_baseurl;
  }

  get endpoint(): string {
    return environment.psl.diligent_endpoint;
  }

  private get options(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${this.token.hash}`)
        .set('content-type', 'application/json')
        .set('Front-End-Version', packageJson.version),
    };
  }

  private get contentFreeOptions(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${this.token.hash}`)
        .set('Front-End-Version', packageJson.version),
    };
  }

  private get feOnlyOptions(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders().set('Front-End-Version', packageJson.version),
    };
  }

  constructor(private http: HttpClient, private token: TokenService, private router: Router) {}

  login(user: string, password: string): Promise<ILoginResponse> {
    return this.http
      .post<ILoginResponse>(
        `${this.endpoint}/login`,
        {
          email: user,
          password,
        },
        { ...this.feOnlyOptions }
      )
      .pipe(
        map((data) => data),
        take(1)
      )
      .toPromise();
  }

  activate(token: string, password: string): Observable<ILoginResponse> {
    return this.http
      .post<ILoginResponse>(
        `${this.endpoint}/user/activate`,
        {
          token,
          password,
        },
        { ...this.feOnlyOptions }
      )
      .pipe(
        map((data) => data),
        take(1)
      );
  }

  getFeatures(): Observable<{ features: Features }> {
    return this.wrapperRenewToken(
      this.http.get<{ features: Features }>(`${this.endpoint}/features`, {
        ...this.options,
      })
    );
  }

  getFeature<T>(featureName: SupportedFeaturesOnDemand, accountId: number): Observable<T> {
    return this.wrapperRenewToken(
      this.http
        .get<{ feature: { [key: string]: T } }>(`${this.endpoint}/feature/${featureName}`, {
          ...this.options,
          params: new HttpParams().set('accountId', accountId),
        })
        .pipe(map((response) => response.feature[featureName]))
    );
  }

  postActivitiesLogs(activities: ActivityLog[], timestamp: number): Observable<{}> {
    return this.wrapperRenewToken(
      this.http.post(
        `${this.endpoint}/activitiesLogs`,
        {
          activities: activities,
          timestamp: timestamp,
        },
        {
          ...this.options,
        }
      )
    );
  }

  getUserById(userId: number, accountId: number): Observable<IUser> {
    return this.wrapperRenewToken(
      this.http.get<IUser>(`${this.endpoint}/user/${userId}`, {
        ...this.options,
        params: new HttpParams().set('accountId', accountId),
      })
    );
  }

  renewToken(returnUrl?: string): Observable<boolean> {
    return this.token.isExpired().pipe(
      switchMap((expired) => {
        return expired
          ? this.http.get(`${this.endpoint}/token`, this.options).pipe(
              switchMap((response: any) => {
                // 401 means the token renew failed ... send an error downstream, or the response object on success
                return !response || response.code === 401 ? throwError(response.payload.message) : of(response);
              }),
              // error renewing token - this can happen if the app is re-opened after being closed beyond token expiration
              catchError((err) => {
                // expire the token/metadata
                this.token.expire();

                // navigate to login, and send false downstream so the token updates can be ignored with skipWhile
                return this.router
                  .navigate(['/login'], {
                    queryParams: { returnUrl: returnUrl },
                  })
                  .then(() => false);
              }),
              // there was an error and the router navigated to login, and sent false back into the stream - skip the rest of the pipes and don't emit
              skipWhile((r) => r === false),
              // only writes token if the response !== false
              tap((response: any) => (this.token.hash = response.token)),
              map(() => true)
            )
          : of(true);
      }),
      // api calls constitute activity - reset the token inactivity timer
      tap(this.token.touch)
    );
  }

  getAccount(accountId: number): Observable<IAccount> {
    return this.wrapperRenewToken(this.http.get<IAccount>(`${this.endpoint}/account/${accountId}`, this.options));
  }

  updateAccount(accountId: number, account: IAccount): Observable<{}> {
    return this.wrapperRenewToken(
      this.http.put(
        `${this.endpoint}/account/${accountId}`,
        {
          ...account,
        },
        { ...this.options }
      )
    );
  }

  getAccounts(accountId: number): Promise<any> {
    return this.wrapperRenewToken(this.http.get(`${this.endpoint}/accounts/`, this.options)).toPromise();
  }

  createAccount(account: IAccount): Observable<any> {
    return this.wrapperRenewToken(
      this.http.post(`${this.endpoint}/account`, account, {
        ...this.options,
      })
    );
  }

  deleteAccount(accountId: number): Observable<MessageReturn> {
    return this.wrapperRenewToken(
      this.http.delete<MessageReturn>(`${this.endpoint}/customerAccount/${accountId}`, this.options)
    );
  }

  resetPassword(oldPassword: string, password: string): Observable<Object> {
    return this.wrapperRenewToken(this.http.post(`${this.endpoint}/password`, { oldPassword, password }, this.options));
  }

  getTrendsDataJson(
    startDate: Moment,
    endDate: Moment,
    granularity: string,
    channelIds: Array<string>,
    measurePointId: string,
    table: string = 'oneminute',
    interval: number = 1
  ): Observable<SubscriptionData> {
    const endpointUrl = `${this.endpoint}/trends/measurementPoint/${measurePointId}`;
    return this.wrapperRenewToken(
      this.http
        .post<Array<TrendData>>(
          endpointUrl,
          {
            startTime: startDate.seconds(0).milliseconds(0).toISOString(),
            endTime: endDate.toISOString(),
            period: granularity,
            columns: channelIds,
            output: 'json',
            writeToFile: false,
            table,
            interval: interval,
          },
          { ...this.options, responseType: 'json', observe: 'response' }
        )
        .pipe(
          map((response: HttpResponse<Array<TrendData>>): SubscriptionData => {
            if (response.body['errorMessage']) {
              const error: any = new Error(response.body['errorMessage']);
              error.message = response.body['errorMessage'];
              throw error;
            } else {
              return parseSubscriptionData(response.headers, response.body);
            }
          }),
          catchError((error): Observable<SubscriptionData> => {
            throw error;
          })
        )
    );
  }

  getMaxTrendsDataJson(
    startDate: Moment,
    endDate: Moment,
    granularity: string,
    channelIds: Array<string>,
    measurePointId: string,
    table: string = 'oneminute',
    interval: number = 1
  ): Observable<SubscriptionData> {
    const endpointUrl = `${this.endpoint}/trends/measurementPoint/${measurePointId}`;
    return this.wrapperRenewToken(
      this.http
        .post<Array<TrendData>>(
          endpointUrl,
          {
            startTime: startDate.seconds(0).milliseconds(0).toISOString(),
            endTime: endDate.toISOString(),
            period: granularity,
            columns: channelIds,
            output: 'json',
            writeToFile: false,
            table,
            interval: interval,
            aggregationType: 'max',
            aggregationInterval: 1,
            aggregationPeriod: 'day',
          },
          { ...this.options, responseType: 'json', observe: 'response' }
        )
        .pipe(
          map((response: HttpResponse<Array<TrendData>>): SubscriptionData => {
            return parseSubscriptionData(response.headers, response.body);
          })
        )
    );
  }

  downloadTrends(downloadForm: TrendsRequest, mpId: string): Observable<string> {
    return this.wrapperRenewToken(
      this.http.post(
        `${this.endpoint}/trends/measurementPoint/${mpId}`,
        {
          startTime: downloadForm.startDate.toISOString(),
          endTime: downloadForm.endDate.toISOString(),
          period: downloadForm.period,
          columns: downloadForm.columns,
          output: downloadForm.output,
          writeToFile: false,
          table: downloadForm.table,
        },
        { ...this.options, responseType: 'text' }
      )
    );
  }

  getAlarmsEventsNotes(
    alarmEventNoteRequest: AlarmEventNoteRequest
  ): Observable<PaginatedModel<AlarmEventNoteResponse>> {
    return this.http.post<PaginatedModel<AlarmEventNoteResponse>>(
      `${this.endpoint}/measurementPoint/alarmsEventsNotes`,
      alarmEventNoteRequest,
      { ...this.options }
    );
  }

  getAlarmsEventsNotesSubscription(alarmEventNoteRequest: AlarmEventNoteRequest): Observable<SubscriptionData> {
    return this.http
      .post<PaginatedModel<AlarmEventNoteResponse>>(
        `${this.endpoint}/measurementPoint/alarmsEventsNotes`,
        alarmEventNoteRequest,
        { ...this.options, observe: 'response' }
      )
      .pipe(
        map((response: HttpResponse<any>): SubscriptionData => {
          return parseSubscriptionData(response.headers, response.body);
        })
      );
  }

  getAlarmsEventsNote(nature: string, id: string): Observable<any> {
    return this.wrapperRenewToken(
      this.http.get(`${this.endpoint}/measurementPoint/alarmsEventsNotes/${nature}/${id}`, {
        ...this.options,
      })
    );
  }

  // TODO create EventsData interface
  getEventsData(
    measurePointId: string,
    accountId: string,
    startDate: Moment,
    endDate: Moment,
    eventTypeId = -1,
    count: number = 10000,
    severeOnly: boolean = true
  ): Observable<any> {
    let paramsQuery = new HttpParams()
      .set('accountId', `${accountId}`)
      .set('includeRetired', 'false')
      .set('dateRangeStart', `${startDate.toISOString()}`)
      .set('dateRangeEnd', `${endDate.toISOString()}`)
      .set('count', `${count}`)
      .set('severeOnly', `${severeOnly}`);

    if (eventTypeId >= 0) {
      paramsQuery = paramsQuery.set('deviceEventTypeId', `${eventTypeId}`);
    }

    return this.wrapperRenewToken(
      this.http.get(`${this.endpoint}/events/measurementPoint/${measurePointId}`, {
        ...this.options,
        params: paramsQuery,
      })
    );
  }

  getMeasurementPointEvents(
    mpId: number,
    accountId: number,
    startDate: Moment = null,
    endDate: Moment = null,
    deviceEventTypeId: any = null,
    severeOnly: boolean = false,
    includeRetired: boolean = false,
    offset: number = 0,
    count: number = 20
  ): Promise<any> {
    const url = `${this.endpoint}/events/measurementPoint/${mpId}`;
    let params = new HttpParams();

    if (accountId) {
      params = params.set('accountId', accountId.toString());
    }

    if (startDate) {
      params = params.set('dateRangeStart', startDate.toISOString());
    }

    if (endDate) {
      params = params.set('dateRangeEnd', endDate.toISOString());
    }

    if (deviceEventTypeId) {
      params = params.set('deviceEventTypeId', deviceEventTypeId);
    }

    if (severeOnly) {
      params = params.set('severeOnly', 'true');
    }

    if (includeRetired) {
      params = params.set('includeRetired', 'true');
    }

    if (!isNaN(offset) && offset >= 0) {
      params = params.set('offset', Math.round(offset).toString());
    }

    if (!isNaN(count) && count >= 0) {
      params = params.set('count', Math.round(count).toString());
    }
    return this.wrapperRenewToken(this.http.get(url, { ...this.options, params: params })).toPromise();
  }

  getWaveformGroups(mpId: number): Observable<any> {
    return this.wrapperRenewToken(this.http.get(`${this.endpoint}/events/channelsGrouping/${mpId}`, this.options));
  }

  getWaveformData(eventId: number): Observable<{ rms: EventViewer; waveform: EventViewer }> {
    return this.wrapperRenewToken(
      this.http.get<{ rms: EventViewer; waveform: EventViewer }>(
        `${this.endpoint}/event/viewerData/${eventId}`,
        this.options
      )
    );
  }

  getPowerQualityMeasures(mpId: number): Promise<any> {
    return this.wrapperRenewToken(
      this.http.get(`${this.endpoint}/powerQualityMeasures/measurementPoint/${mpId}`, this.options)
    ).toPromise();
  }

  getSites(accountId: number = 0, query?: any, includeRetired: boolean = false): Observable<any> {
    const url = `${this.endpoint}/measurementPoints/hierarchy`;
    let params = new HttpParams();

    if (accountId && accountId > 0) {
      query = { ...query, accountId: accountId.toString() };
      params = params.set('accountId', accountId.toString());
    } else {
      query = { ...query, excludeMeasurementPoints: false, excludeMeasures: true };
      params = params.set('excludeMeasurementPoints', 'false');
      params = params.set('excludeMeasures', 'true');
    }
    return this.wrapperRenewToken(this.http.get(url, { ...this.options, params: query as any }));
    // .toPromise();
  }

  getMeasurementPointsForMap(query: any): Observable<Array<IMeasurementPoint>> {
    let accountId = 0;
    let includeRetired = false;
    const url = `${this.endpoint}/measurementPoints/mapWithKpis`;
    let params = new HttpParams();

    if (accountId && accountId > 0) {
      query = { ...query, accountId: accountId.toString() };
      params = params.set('accountId', accountId.toString());
    }

    if (includeRetired) {
      query = { ...query, includeRetired: 'true' };
      params = params.set('includeRetired', 'true');
    }
    return this.wrapperRenewToken(
      this.http.get<Array<IMeasurementPoint>>(url, { ...this.options, params: query as any })
    );
  }

  getMeasurementPointsForMapPromise(
    accountId: number = 0,
    includeRetired: boolean = false
  ): Promise<Array<IMeasurementPoint>> {
    const url = `${this.endpoint}/measurementPoints/mapWithKpis`;
    let params = new HttpParams();

    if (accountId && accountId > 0) {
      params = params.set('accountId', accountId.toString());
    }

    if (includeRetired) {
      params = params.set('includeRetired', 'true');
    }
    return this.wrapperRenewToken(
      this.http.get<Array<IMeasurementPoint>>(url, { ...this.options, params: params })
    ).toPromise();
  }

  getMoveMpList(mpId: number): Observable<Array<MoveToList>> {
    return this.wrapperRenewToken(
      this.http.get<Array<MoveToList>>(`${this.endpoint}/measurementPoint/${mpId}/move/accounts`, this.options)
    );
  }

  moveMp(sourceMpId: number, targetAccountId: number): Observable<IMeasurementPoint> {
    return this.wrapperRenewToken(
      this.http.post<IMeasurementPoint>(
        `${this.endpoint}/measurementPoint/move/device`,
        { sourceMeasurementPointId: sourceMpId, targetAccountId: targetAccountId },
        { ...this.options }
      )
    );
  }

  startReplacement(mpId: number): Observable<ReplacementStep> {
    return this.wrapperRenewToken(
      this.http.post<ReplacementStep>(
        `${this.endpoint}/measurementPoint/deviceReplacement`,
        { measurementPointId: mpId },
        { ...this.options }
      )
    );
  }

  completeStep(replacementId: number, step: number): Observable<ReplacementStep> {
    return this.wrapperRenewToken(
      this.http.post<ReplacementStep>(
        `${this.endpoint}/measurementPoint/${replacementId}/deviceReplacement/completeStep`,
        { step: step },
        { ...this.options }
      )
    );
  }

  getReplacementStep(mpId: number): Observable<Array<ReplacementStep>> {
    return this.wrapperRenewToken(
      this.http.get<Array<ReplacementStep>>(
        `${this.endpoint}/measurementPoint/${mpId}/deviceReplacements`,
        this.options
      )
    );
  }

  getSite(siteId: number): Promise<any> {
    return this.wrapperRenewToken(this.http.get(`${this.endpoint}/site/${siteId}`, this.options)).toPromise();
  }

  getUserPreferences(): Observable<UserPreferences> {
    return this.wrapperRenewToken(this.http.get<UserPreferences>(`${this.endpoint}/user/preferences`, this.options));
  }

  getUsersAccessByMp(
    mpId: number,
    offset: number = 0,
    count: number = 10,
    sortBy: string = null,
    sortAsc: boolean = true
  ): Observable<PaginatedModel<UserAccess>> {
    let params = new HttpParams();
    params = params.set('offset', offset);
    params = params.set('count', count);
    if (sortBy) {
      params = params.set('sortColumns', sortBy);
      params = params.set('ascColumns', sortAsc);
    }
    return this.wrapperRenewToken(
      this.http.get<PaginatedModel<UserAccess>>(`${this.endpoint}/users/access/${mpId}`, {
        ...this.options,
        params: params,
      })
    );
  }

  getMeasurementPointAccessesByUser(
    userId: number,
    accountId: number,
    offset: number = 0,
    count: number = 10
  ): Observable<PaginatedModel<MeasurementPointAccess>> {
    let params = new HttpParams();
    params = params.set('accountId', accountId);
    params = params.set('offset', offset);
    params = params.set('count', count);
    return this.wrapperRenewToken(
      this.http.get<PaginatedModel<MeasurementPointAccess>>(`${this.endpoint}/measurementPoints/access/${userId}`, {
        ...this.options,
        params: params,
      })
    );
  }

  putMpUserAccess(mpId: number, payload: { userId: number; access: boolean }[]): Observable<any> {
    return this.wrapperRenewToken(
      this.http.put<PaginatedModel<UserAccess>>(`${this.endpoint}/measurementPoint/access/${mpId}`, payload, {
        ...this.options,
      })
    );
  }
  saveUserPreferences(prefs, update = false): Promise<any> {
    let method = 'post';
    // TODO: figure out automatically if we're updating or creating...
    if (update) {
      method = 'put';
    }
    return this.wrapperRenewToken(
      this.http[method](`${this.endpoint}/user/preferences`, prefs, this.options)
    ).toPromise();
  }

  getHistoricalReportsData(mpId = null, startYear = null, endYear = null): Promise<any> {
    let params = new HttpParams();

    if (startYear) {
      params = params.set('startYear', startYear);
    }

    if (endYear) {
      params = params.set('endYear', endYear);
    }
    return this.wrapperRenewToken(
      this.http.get(`${this.endpoint}/historicalPowerQualitymeasures/measurementPoint/${mpId}`, {
        ...this.options,
        params: params,
      })
    ).toPromise();
  }

  getEventImages(eventId: number): Promise<any> {
    return this.wrapperRenewToken(this.http.get(`${this.endpoint}/event/images/${eventId}`, this.options)).toPromise();
  }

  getEventFiles(eventId: number, type: Array<string>): Observable<Array<EventFile>> {
    return this.wrapperRenewToken(
      this.http.get<Array<EventFile>>(`${this.endpoint}/event/files/${eventId}`, {
        ...this.options,
        params: { fileTypes: type },
      })
    );
  }

  getDocumentDownload(documentId: number, ttl: number = 300): Promise<any> {
    const params = new HttpParams().set('ttl', ttl.toString());
    return this.wrapperRenewToken(
      this.http.get(`${this.endpoint}/document/download/${documentId}`, {
        ...this.options,
        responseType: 'text',
        params: params,
      })
    ).toPromise();
  }

  getMeasurementPoint(mpId: number): Observable<any> {
    return this.wrapperRenewToken(this.http.get(`${this.endpoint}/measurementPoint/${mpId}`, this.options));
  }

  deleteMp(mpId: number): Observable<MessageReturn> {
    return this.wrapperRenewToken(
      this.http.delete<MessageReturn>(`${this.endpoint}/measurementPoint/${mpId}`, this.options)
    );
  }

  getLoads(mpId: number): Observable<Loads> {
    return this.wrapperRenewToken(
      this.http.get<Loads>(`${this.endpoint}/loads/measurementPoint/${mpId}`, this.options)
    );
  }

  getSocketUrl(): Observable<string> {
    return this.wrapperRenewToken(
      this.http.get(`${this.endpoint}/mqtt/url`, {
        ...this.options,
        responseType: 'text',
      })
    );
  }

  getSocketTopic(accountId: number, mpId: number): Observable<any> {
    const params = new HttpParams().set('accountId', `${accountId}`);

    return this.wrapperRenewToken(
      this.http.get(`${this.endpoint}/mqtt/topic/${mpId}`, {
        ...this.options,
        params: params,
        responseType: 'text',
      })
    );
  }

  // start data publishing from PQube
  initiatePQubePublish(channelArray: Array<number>, mpId: number, accountId: number): void {
    const params = new HttpParams().set('accountId', `${accountId}`);
    const endpointUrl = `${this.endpoint}/mqtt/channels/${mpId}`;
    this.wrapperRenewToken(
      this.http.post(endpointUrl, channelArray, {
        ...this.options,
        responseType: 'json',
        params,
      })
    ).subscribe();
  }

  getMeasurementPoints(
    accountId: number,
    includeRetired: boolean,
    offset: number = 0,
    count: number = 20
  ): Observable<Array<IMeasurementPoint>> {
    let params = new HttpParams().set('accountId', accountId.toString());

    if (includeRetired) {
      params = params.set('includeRetired', 'true');
    }

    if (!isNaN(offset) && offset >= 0) {
      params = params.set('offset', offset.toString());
    }

    if (!isNaN(count) && count >= 0) {
      params = params.set('count', count.toString());
    }

    return this.wrapperRenewToken(
      this.http.get(`${this.endpoint}/measurementPoints`, {
        ...this.options,
        params: params,
      }) as Observable<Array<IMeasurementPoint>>
    );
  }

  requestPasswordReset(email: string): Promise<any> {
    return this.http.get(`${this.endpoint}/password/${email}`).pipe(take(1)).toPromise();
  }

  resetPasswordWithToken(password: string, token: string): Promise<ILoginResponse> {
    return this.http
      .post<ILoginResponse>(`${this.endpoint}/password/reset`, {
        token: token,
        password: password,
      })
      .pipe(take(1))
      .toPromise();
  }

  updateUser(userUpdateParams: IUserUpdateParams, userId: number): Observable<IUserUpdateResponse> {
    return this.wrapperRenewToken(
      this.http.put<IUserUpdateResponse>(
        `${this.endpoint}/user/${userId}`,
        {
          ...userUpdateParams,
        },
        { ...this.options }
      )
    );
  }

  getDeviceConfig(mpId: number, section: string, parameter: string): Observable<any> {
    return this.wrapperRenewToken(
      this.http.get(`${this.endpoint}/deviceConfig/${mpId}/parameter`, {
        params: { section: section, parameter: parameter },
        ...this.options,
      })
    );
  }

  associateDeviceWithToken(measurementPointId: number, associateToken: string): Observable<void> {
    return this.wrapperRenewToken(
      this.http.put<void>(
        `${this.endpoint}/associateDeviceToMeasurementPoint/associateToken/${associateToken}/${measurementPointId}`,
        {},
        { ...this.options }
      )
    );
  }

  updatePartner(accountId: number, partnerId: number): Observable<[]> {
    return this.wrapperRenewToken(
      this.http.post<[]>(
        `${this.endpoint}/associateAccountToPartner`,
        { customerAccountId: accountId, partnerAccountId: partnerId },
        { ...this.options }
      )
    );
  }

  disassociateDevice(measurementPointId: number): Observable<MessageReturn> {
    return this.wrapperRenewToken(
      this.http.put<MessageReturn>(
        `${this.endpoint}/disassociateDeviceFromMeasurementPoint/${measurementPointId}`,
        {},
        { ...this.options }
      )
    );
  }

  retryAssociation(mpId: number): Observable<any> {
    return this.wrapperRenewToken(
      this.http.get(`${this.endpoint}/measurementPoint/${mpId}/retryDeviceRegistration`, { ...this.options })
    );
  }

  disassociateUserAccount(userId: number, accountId: number): Observable<{}> {
    return this.wrapperRenewToken(
      this.http.delete(`${this.endpoint}/disassociateUserFromAccount/${accountId}/${userId}`, { ...this.options })
    );
  }

  forceDownloadText(url: string): Promise<any> {
    return this.http.get(url, { responseType: 'text' }).pipe(take(1)).toPromise();
  }

  forceDownloadBlob(url: string): Promise<any> {
    return this.http.get(url, { responseType: 'blob' }).pipe(take(1)).toPromise();
  }

  getNotes(
    mpId: number,
    startDate: moment.Moment,
    endDate: moment.Moment,
    count: number = 1000
  ): Observable<Array<INote>> {
    let queryParam = new HttpParams().set('count', String(count));

    if (startDate) queryParam = queryParam.set('dateRangeStart', startDate.toISOString());
    if (endDate) queryParam = queryParam.set('dateRangeEnd', endDate.toISOString());
    return this.wrapperRenewToken(
      this.http.get<Array<INote>>(`${this.endpoint}/notes/${mpId}`, {
        ...this.options,
        responseType: 'json',
        params: queryParam,
      })
    );
  }

  getNoteDocuments(noteId: number, count: number = 1000): Observable<Array<string>> {
    const params = new HttpParams().set('count', String(count));
    return this.renewToken().pipe(
      switchMap(() =>
        this.http.get(`${this.endpoint}/note/documents/${noteId}`, {
          ...this.options,
          responseType: 'json',
          params,
        })
      ),
      map((response) => response as { documents: Array<any>; more: boolean }),
      switchMap((response) =>
        forkJoin([
          ...response.documents.map((doc) =>
            this.http
              .get(`${this.endpoint}/document/download/${doc.documentId}`, {
                ...this.options,
                responseType: 'text',
              })
              .pipe(
                map((imgUrl) => {
                  doc.imgUrl = imgUrl;
                  return doc;
                })
              )
          ),
        ])
      ),
      map((response) => response as Array<any>),
      take(1)
    );
  }

  submitNewNote(formData: FormData, mpId: number): Observable<{ id: number }> {
    const httpHeaders = new HttpHeaders().set('Authorization', `Bearer ${this.token.hash}`);
    return this.wrapperRenewToken(
      this.http.post<{ id: number }>(`${this.endpoint}/note/${mpId}`, formData, {
        ...this.options,
        headers: httpHeaders,
      })
    );
  }

  updateNote(formData: FormData, mpId: number): Observable<{ status: string }> {
    const httpHeaders = new HttpHeaders().set('Authorization', `Bearer ${this.token.hash}`);
    return this.wrapperRenewToken(
      this.http.patch<{ status: string }>(`${this.endpoint}/note/${mpId}`, formData, {
        ...this.options,
        headers: httpHeaders,
      })
    );
  }

  deleteNote(noteId: number): Observable<{ status: string }> {
    return this.wrapperRenewToken(
      this.http.delete<{ status: string }>(`${this.endpoint}/note/${noteId}`, {
        ...this.options,
      })
    );
  }

  tempGetAccountUsersList(
    accountId: string,
    count: number,
    offset: number,
    sortActive: string,
    sortDirection: SortDirection,
    includePartner: boolean,
    exceptUserRoles: Array<string>,
    mpIds: Array<string | number>
  ): Observable<PaginatedModel<IMember>> {
    let params = {
      accountId: accountId,
      includeRetired: 'false',
      count: count,
      offset: offset,
      includePartner: includePartner ? includePartner : 'false',
      exceptUserRoles,
    };
    if (mpIds && mpIds.length > 0) {
      params['mpIds'] = mpIds.join(',');
    }
    if (sortActive) {
      params['sortColumns'] = sortActive;
      params['ascColumns'] = sortDirection === 'asc' ? 'true' : 'false';
    }
    return this.wrapperRenewToken(
      this.http.get<PaginatedModel<IMember>>(`${this.endpoint}/users`, {
        ...this.options,
        params: params,
      })
    );
  }

  getAccountUsersList(query: IMemberQuery): Observable<PaginatedModel<IMember>> {
    return this.wrapperRenewToken(
      this.http.get<PaginatedModel<IMember>>(`${this.endpoint}/users`, {
        ...this.options,
        params: query as any,
      })
    );
  }

  addUser(addUserParams: IUserUpdateParams): Observable<{ id: number }> {
    return this.wrapperRenewToken(
      this.http.post<{ id: number }>(`${this.endpoint}/user`, { ...addUserParams }, { ...this.options })
    );
  }

  resendActivationEmail(userId: number, accountId: number): Observable<{ id: number }> {
    return this.wrapperRenewToken(
      this.http.get<{ id: number }>(`${this.endpoint}/user/resend/${userId}`, {
        ...this.options,
        params: new HttpParams().set('accountId', accountId.toString()),
      })
    );
  }

  getEnergyMeasure(start: Moment, end: Moment, mp: string, loads?: Array<number>): Observable<IEnergyMeasure> {
    return this.wrapperRenewToken(
      this.http.get<IEnergyMeasure>(`${this.endpoint}/energy/measurementPoint/${mp}`, {
        ...this.options,
        params: {
          dateRangeStart: start.toISOString(),
          dateRangeEnd: end.toISOString(),
          loads: loads ? loads : [],
        },
      })
    );
  }

  getMonthlyReportsPerYear(mp: number, year: number): Observable<Array<IMonthlyReport>> {
    return this.wrapperRenewToken(
      this.http.get<Array<IMonthlyReport>>(`${this.endpoint}/monthlyReports/${mp}/${year}`, {
        ...this.options,
      })
    );
  }

  getMonthlyReportsOrCreate(mp: number, year: number, month: number, create: string): Observable<IMonthlyReport> {
    return this.wrapperRenewToken(
      this.http.get<IMonthlyReport>(`${this.endpoint}/monthlyReports/${mp}/${year}/${month}`, {
        ...this.options,
        params: { createIfNotExists: create },
      })
    );
  }

  actionReport(report, type: string): Observable<any> {
    let action: string;
    if (type === 'save') {
      action = '';
    } else {
      action = '/' + type;
    }
    return this.wrapperRenewToken(
      this.http.patch<any>(`${this.endpoint}/monthlyReports/${report.id}${action}`, { ...report }, { ...this.options })
    );
  }

  publishReport(report): Observable<any> {
    return this.wrapperRenewToken(
      this.http.patch<any>(`${this.endpoint}/monthlyReports/${report.id}/publish`, { ...report }, { ...this.options })
    );
  }

  archiveReport(report): Observable<any> {
    return this.wrapperRenewToken(
      this.http.patch<any>(`${this.endpoint}/monthlyReports/${report.id}/archive`, { ...report }, { ...this.options })
    );
  }

  registerUserAccount(userAccount): Observable<any> {
    return this.http.post(`${this.endpoint}/registerAccount`, userAccount).pipe(take(1));
  }

  publishDocuments(formData: FormData): Observable<any> {
    return this.http
      .post(`${this.endpoint}/monthlyReportDocuments`, formData, {
        ...{
          headers: new HttpHeaders().set('Authorization', `Bearer ${this.token.hash}`),
        },
      })
      .pipe(take(1));
  }

  createsiteMp(accountId: number, siteMp: ISiteMp): Observable<any> {
    return this.wrapperRenewToken(
      this.http.post(`${this.endpoint}/site/measurementPoint`, siteMp, {
        ...this.options,
        params: { accountId: accountId.toString() },
      })
    );
  }

  updatesiteMp(accountId: number, siteId: number, measurementPointId: number, siteMp: ISiteMp): Observable<any> {
    return this.wrapperRenewToken(
      this.http.patch(`${this.endpoint}/site/${siteId}/measurementPoint/${measurementPointId}`, siteMp, {
        ...this.options,
        params: { accountId: accountId.toString() },
      })
    );
  }

  updateMpStatus(mpId: number, mpStatusId: number, siteMp: ISiteMp): Observable<any> {
    return this.wrapperRenewToken(
      this.http.patch(`${this.endpoint}/status/measurementPoint/${mpId}/${mpStatusId}`, siteMp, { ...this.options })
    );
  }

  countMeasurementPointsByAccount(accountId: number): Observable<any> {
    return this.wrapperRenewToken(
      this.http.get(`${this.endpoint}/measurementPoints/countByAccount`, {
        ...this.options,
        params: { accountId: accountId.toString() },
      })
    );
  }

  getChannelDefinition(mpId: string, type: string, mpTypeId: number, groups?: any): Observable<ChannelObject> {
    const param = {};
    if (type !== '') {
      Object.assign(param, { channelType: type });
    }
    if (groups) {
      Object.assign(param, { groups: groups });
    }
    if (mpTypeId === 1) {
      Object.assign(param, { defaultFallback: 'QubeScan' });
    } else if (mpTypeId === 3) {
      Object.assign(param, { defaultFallback: 'DriveScan' });
    } else {
      Object.assign(param, { defaultFallback: 'default' });
    }
    return this.wrapperRenewToken(
      this.http.get<ChannelObject>(`${this.endpoint}/channelDefinition/${mpId}`, {
        ...this.options,
        params: param,
      })
    );
  }

  getLastMpTrends(
    mpId: string,
    channels: Array<string>,
    table: string
  ): Observable<DrivescanValue | DrivescanDashboard> {
    return this.wrapperRenewToken(
      this.http.post<DrivescanValue | DrivescanDashboard>(
        `${this.endpoint}/trends/last/measurementPoint/${mpId}`,
        {
          table: table,
          columns: channels,
        },
        this.options
      )
    );
  }

  getEventsCount(mpId: string, eventList: Array<string>): Observable<Array<EventCount>> {
    return this.wrapperRenewToken(
      this.http.post<Array<EventCount>>(`${this.endpoint}/count/events/measurementPoint/${mpId}`, eventList, {
        ...this.options,
        params: { includeNonSevere: 'true' },
      })
    );
  }

  getDrivescanInfo(mpId: string): Observable<DrivescanInfo> {
    return this.wrapperRenewToken(
      this.http.get<DrivescanInfo>(`${this.endpoint}/drive/${mpId}`, {
        ...this.options,
      })
    );
  }

  getDriveParameters(mpId: string): Observable<DrivescanParameters> {
    return this.wrapperRenewToken(
      this.http.get<DrivescanParameters>(`${this.endpoint}/parameters/${mpId}`, {
        ...this.options,
        params: { filterParams: ['ratedCurrent', 'nominalPhaseToPhaseVoltage', 'nominalPhaseToNeutralVoltage'] },
      })
    );
  }

  getCloudAlarms(mpId: string, startDate: Moment, endDate: Moment): Observable<Array<Alarm>> {
    return this.wrapperRenewToken(
      this.http.get<Array<Alarm>>(`${this.endpoint}/cloudAlarms/${mpId}`, {
        ...this.options,
        params: {
          includeCleared: 'true',
          dateRangeStart: startDate.toISOString(),
          dateRangeEnd: endDate.toISOString(),
        },
      })
    );
  }

  clearSingleCloudAlarm(alarmId: string, alarm): Observable<ClearAlarmReturn> {
    const httpHeaders = new HttpHeaders().set('Authorization', `Bearer ${this.token.hash}`);
    return this.wrapperRenewToken(
      this.http.patch<ClearAlarmReturn>(`${this.endpoint}/cloudAlarms/clear/${alarmId}`, alarm, {
        ...this.options,
        headers: httpHeaders,
      })
    );
  }

  clearAllCloudAlarm(mpId: string, alarms): Observable<ClearAlarmReturn> {
    return this.wrapperRenewToken(
      this.http.patch<ClearAlarmReturn>(`${this.endpoint}/cloudAlarms/clear`, alarms, {
        ...this.options,
        params: { measurementPointId: mpId },
      })
    );
  }

  clearTypeCloudAlarm(mpId: string, channel: string, alarm): Observable<any> {
    return this.wrapperRenewToken(
      this.http.patch<ClearAlarmReturn>(`${this.endpoint}/cloudAlarms/clear`, alarm, {
        ...this.options,
        params: { measurementPointId: mpId, channel: channel },
      })
    );
  }

  getRecommendationCount(measurementPointId: string, year: string): Observable<RecommendationCount> {
    return this.wrapperRenewToken(
      this.http.get<RecommendationCount>(`${this.endpoint}/recommendation/count`, {
        ...this.options,
        params: {
          measurementPointId,
          year,
        },
      })
    );
  }

  clearAllTypeEvent(mpId: string, eventTypeId: string, event): Observable<any> {
    const httpHeaders = new HttpHeaders().set('Authorization', `Bearer ${this.token.hash}`);
    return this.wrapperRenewToken(
      this.http.patch<MessageReturn>(`${this.endpoint}/events/clear`, event, {
        ...this.options,
        params: {
          measurementPointId: mpId,
          eventTypeId,
        },
        headers: httpHeaders,
      })
    );
  }

  clearEvent(mpId: string, event, eventId?: string, eventTypeId?: string): Observable<MessageReturn> {
    const httpHeaders = new HttpHeaders().set('Authorization', `Bearer ${this.token.hash}`);
    const param = {};
    if (eventId) {
      Object.assign(param, {
        eventId,
        measurementPointId: mpId,
        eventTypeId,
        isCleared: 'true',
      });
    } else {
      Object.assign(param, {
        measurementPointId: mpId,
        isCleared: 'true',
      });
    }

    return this.wrapperRenewToken(
      this.http.patch<MessageReturn>(`${this.endpoint}/events/clear`, event, {
        ...this.options,
        params: param,
        headers: httpHeaders,
      })
    );
  }

  getRecommendation(measurementPointId: string): Observable<Array<IRecommendation>> {
    return this.wrapperRenewToken(
      this.http.get<Array<IRecommendation>>(`${this.endpoint}/recommendation`, {
        ...this.options,
        params: {
          measurementPointId,
        },
      })
    );
  }

  createRecommendationComment(recommendationId: number, comment: Comment): Observable<RecommendationComment> {
    return this.wrapperRenewToken(
      this.http.post<RecommendationComment>(`${this.endpoint}/recommendation/comment/${recommendationId}`, comment, {
        ...this.options,
      })
    );
  }

  updateRecommendationComment(
    recommendationId: number,
    commentId: number,
    comment: Comment
  ): Observable<RecommendationComment> {
    return this.wrapperRenewToken(
      this.http.patch<RecommendationComment>(`${this.endpoint}/recommendation/comment/${recommendationId}`, comment, {
        ...this.options,
        params: { commentId: commentId.toString() },
      })
    );
  }

  getRecommendationComments(recommendationId: number): Observable<Array<RecommendationComment>> {
    return this.wrapperRenewToken(
      this.http.get<Array<RecommendationComment>>(`${this.endpoint}/recommendation/comment/${recommendationId}`, {
        ...this.options,
      })
    );
  }

  updateRecommendation(body: IRecommendation): Observable<IRecommendation> {
    return this.wrapperRenewToken(
      this.http.patch<IRecommendation>(`${this.endpoint}/recommendation/${body.id}`, body, { ...this.options })
    );
  }

  createRecommendation(body: IRecommendation): Observable<IRecommendation> {
    return this.wrapperRenewToken(
      this.http.post<IRecommendation>(`${this.endpoint}/recommendation`, body, {
        ...this.options,
      })
    );
  }

  sendRecommendation(recommendationId: number): Observable<SendRecommendationResponse> {
    return this.wrapperRenewToken(
      this.http.get<SendRecommendationResponse>(`${this.endpoint}/recommendation/email/${recommendationId}/send`, {
        ...this.options,
      })
    );
  }

  getFiltersTypesPerDate(filterTypeRequest: FilterTypeRequest): Observable<EventFilterType> {
    return this.wrapperRenewToken(
      this.http.get<EventFilterType>(`${this.endpoint}/measurementPoint/alarmEventNoteTypesPerDateRange`, {
        ...this.options,
        params: {
          measurementPointId: filterTypeRequest.mpId,
          dateRangeStart: filterTypeRequest.dateRangeStart.toISOString(),
          dateRangeEnd: filterTypeRequest.dateRangeEnd.toISOString(),
        },
      })
    );
  }

  getTrendAlarmModels(query: TrendAlarmModelQuery): Observable<Array<TrendAlarmModel>> {
    return this.wrapperRenewToken(
      this.http.get<Array<TrendAlarmModel>>(`${this.endpoint}/trendAlarmModels`, {
        ...this.options,
        params: query as any,
      })
    );
  }

  updateTrendAlarmModels(trendAlarmModel: TrendAlarmModel): Observable<TrendAlarmModel> {
    return this.wrapperRenewToken(
      this.http.patch<TrendAlarmModel>(`${this.endpoint}/trendAlarmModels/${trendAlarmModel.id}`, trendAlarmModel, {
        ...this.options,
      })
    );
  }

  createTrendAlarmModels(trendAlarmModel: TrendAlarmModel): Observable<TrendAlarmModel> {
    return this.wrapperRenewToken(
      this.http.post<TrendAlarmModel>(`${this.endpoint}/trendAlarmModels`, trendAlarmModel, {
        ...this.options,
      })
    );
  }

  retireTrendAlarmModels(trendAlarmModelId: number): Observable<void> {
    return this.wrapperRenewToken(
      this.http.delete<void>(`${this.endpoint}/trendAlarmModels/${trendAlarmModelId}`, {
        ...this.options,
      })
    );
  }

  getMpTrendAlarm(mpId: number, includeDisabled = false): Observable<Array<TrendAlarm>> {
    return this.wrapperRenewToken(
      this.http.get<Array<TrendAlarm>>(`${this.endpoint}/trendAlarmModels`, {
        ...this.options,
        params: {
          measurementPointId: mpId,
          includeDisabled: includeDisabled,
        },
      })
    );
  }

  getTrendAlarmChannelList(mpId: number): Observable<Array<TrendAlarmModel>> {
    return this.wrapperRenewToken(
      this.http.get<Array<TrendAlarmModel>>(`${this.endpoint}/trendAlarmModels/channels/${mpId}`, { ...this.options })
    );
  }

  getFirmwareVersions(isSysAdmin: boolean): Observable<Array<FirmwareVersion>> {
    return this.wrapperRenewToken(
      this.http.get<Array<FirmwareVersion>>(`${this.endpoint}/firmwareVersion`, {
        params: { availableOnly: !isSysAdmin },
        ...this.options,
      })
    );
  }

  getFirmwareFleetStatus(query: FirmwareFleetStatusQuery): Observable<FirmwareFleet<FirmwareFleetStatus>> {
    return this.wrapperRenewToken(
      this.http.get<FirmwareFleet<FirmwareFleetStatus>>(`${this.endpoint}/firmware/fleetStatus/search`, {
        ...this.options,
        params: query as any,
      })
    );
  }

  promoteVersion(versionList: Array<string>, promote: boolean): Observable<MessageReturn> {
    return this.wrapperRenewToken(
      this.http.post<MessageReturn>(`${this.endpoint}/firmwareVersion/promote`, versionList, {
        params: { isAvailable: promote },
        ...this.options,
      })
    );
  }

  deleteFirmware(version: string): Observable<MessageReturn> {
    return this.wrapperRenewToken(
      this.http.delete<MessageReturn>(`${this.endpoint}/firmwareVersion/${version}`, { ...this.options })
    );
  }

  createFirmwareVersion(params: FormData): Observable<FirmwareVersion> {
    const updatedHeaders = this.options.headers.delete('content-type');
    return this.wrapperRenewToken(
      this.http.post<FirmwareVersion>(`${this.endpoint}/firmwareVersion`, params, {
        ...this.options,
        headers: updatedHeaders,
      })
    );
  }

  setVersionToDevices(versionId: string, deviceIds: Array<number>): Observable<boolean> {
    return this.wrapperRenewToken(
      this.http.post<boolean>(`${this.endpoint}/firmwareVersion/setToDevices/${versionId}`, deviceIds, {
        ...this.options,
      })
    );
  }

  startUpdateVersions(deviceIds: Array<number>): Observable<boolean> {
    return this.wrapperRenewToken(
      this.http.post<boolean>(`${this.endpoint}/firmware/update/start`, deviceIds, {
        ...this.options,
      })
    );
  }

  getDriveFaultState(mpId: number): Observable<DriveFaultState> {
    return this.wrapperRenewToken(
      this.http.get<DriveFaultState>(`${this.endpoint}/measurementPoint/${mpId}/state`, {
        ...this.options,
      })
    );
  }

  getMpParameters(measurementPointId: number, includeDefinition = true): Observable<MpParameters> {
    return this.wrapperRenewToken(
      this.http.get<MpParameters>(`${this.endpoint}/parameters/${measurementPointId}`, {
        params: {
          includeDefinition,
        },
        ...this.options,
      })
    );
  }

  getMaintenanceCommands(): Observable<Array<MaintenanceCommand>> {
    return this.wrapperRenewToken(
      this.http.get<Array<MaintenanceCommand>>(`${this.endpoint}/measurementPoint/maintenance/command`, {
        ...this.options,
      })
    );
  }

  getMaintenanceRequests(mpId: number): Observable<Array<MaintenanceRequestState>> {
    return this.wrapperRenewToken(
      this.http.get<Array<MaintenanceRequestState>>(`${this.endpoint}/measurementPoint/${mpId}/maintenance`, {
        ...this.options,
      })
    );
  }

  sendMaintenanceCommands(mpId: number, commandParams: FormData): Observable<any> {
    const updatedHeaders = this.options.headers.delete('content-type');

    return this.wrapperRenewToken(
      this.http.post(`${this.endpoint}/measurementPoint/${mpId}/maintenance`, commandParams, {
        ...this.options,
        headers: updatedHeaders,
      })
    );
  }

  createMaintenanceRequest(
    mpId: number,
    request: MaintenanceRequest,
    file?: File
  ): Observable<MaintenanceRequestState> {
    const formData = new FormData();
    if (file) {
      formData.append('file', file);
    }
    formData.append('parameters', JSON.stringify(request));
    return this.wrapperRenewToken(
      this.http.post<MaintenanceRequestState>(`${this.endpoint}/measurementPoint/${mpId}/maintenance`, formData, {
        ...this.contentFreeOptions,
      })
    );
  }

  getSetupINI(mpId: number): Promise<any> {
    return this.wrapperRenewToken(
      this.http.get(`${this.endpoint}/measurementPoint/${mpId}/setup`, { ...this.options, responseType: 'text' })
    ).toPromise();
  }

  saveMpParameters(parameters: MpParameters): Observable<MpParameters> {
    return this.wrapperRenewToken(
      this.http.put<MpParameters>(`${this.endpoint}/parameters`, parameters, { ...this.options })
    );
  }

  addMeasurementPointFile(measurementPointId: number, formData: FormData): Observable<any> {
    return this.wrapperRenewToken(
      this.http.post(`${this.endpoint}/measurementPoint/${measurementPointId}/file`, formData, {
        ...this.contentFreeOptions,
      })
    );
  }

  getMeasurementPointFiles(measurementPointId: number): Observable<any> {
    return this.wrapperRenewToken(
      this.http.get(`${this.endpoint}/measurementPoint/${measurementPointId}/file`, {
        ...this.options,
      })
    );
  }

  getHFEmissionsFiles(mpId: number, startDate?, endDate?): Observable<SubscriptionData> {
    return this.wrapperRenewToken(
      this.http
        .get(`${this.endpoint}/measurementPointFile/hfEmissionsFiles/${mpId}`, {
          ...this.options,
          params: {
            startDate: startDate,
            endDate: endDate,
          },
          observe: 'response',
        })
        .pipe(
          map((response: HttpResponse<any>): SubscriptionData => {
            return parseSubscriptionData(response.headers, response.body);
          })
        )
    );
  }

  updateCommissioningDate(mpId: number, date: moment.Moment): Observable<MessageReturn> {
    return this.wrapperRenewToken(
      this.http.patch<MessageReturn>(
        `${this.endpoint}/measurementPoint/${mpId}/commissioningDate`,
        { commissioningDate: date },
        {
          ...this.options,
          params: { commissioningDate: date.toISOString() },
        }
      )
    );
  }

  getKPI(mpId: number): Observable<Kpi> {
    return this.wrapperRenewToken(
      this.http.get<Kpi>(`${this.endpoint}/measurementPoint/${mpId}/alarmKPI`, {
        ...this.options,
      })
    );
  }

  private wrapperRenewToken<T>(obs: Observable<T>): Observable<T> {
    return this.renewToken().pipe(switchMap(() => obs));
  }

  getPlaces(sessionToken: string, languageCode: string, regionCode: string, input: string): Observable<any[]> {
    return this.wrapperRenewToken(
      this.http.get<any[]>(`${this.endpoint}/geocoder/places`, {
        ...this.options,
        params: {
          sessionToken,
          languageCode,
          regionCode,
          input,
        },
      })
    );
  }

  getPlaceDetails(
    sessionToken: string,
    languageCode: string,
    regionCode: string,
    placeId: string,
    fields: string
  ): Observable<any> {
    return this.wrapperRenewToken(
      this.http.get<any[]>(`${this.endpoint}/geocoder/place/details`, {
        ...this.options,
        params: {
          sessionToken,
          languageCode,
          regionCode,
          placeId,
          fields,
        },
      })
    );
  }

  getNews(): Observable<News> {
    return this.wrapperRenewToken(
      this.http.get<News>(`${this.endpoint}/newsCommunications/latest`, {
        ...this.options,
      })
    );
  }

  addNews(content: string): Observable<News> {
    return this.wrapperRenewToken(
      this.http.post<News>(
        `${this.endpoint}/newsCommunications`,
        { content: content },
        {
          ...this.options,
        }
      )
    );
  }

  updateNews(news: News): Observable<News> {
    return this.wrapperRenewToken(
      this.http.put<News>(
        `${this.endpoint}/newsCommunications/${news.id}`,
        { content: news.content },
        {
          ...this.options,
        }
      )
    );
  }

  addSagDirection(eventId: number, direction: string): Observable<MessageReturn> {
    return this.wrapperRenewToken(
      this.http.post<MessageReturn>(
        `${this.endpoint}/event/sagDirection/${eventId}`,
        { direction: direction },
        {
          ...this.options,
        }
      )
    );
  }

  getEnumerations(enumerations?: Array<string>): Observable<any> {
    return this.wrapperRenewToken(this.http.post(`${this.endpoint}/enumerations`, enumerations, { ...this.options }));
  }

  getReportResult(request: ReportWriterHistoryRequest): Observable<SubscriptionData> {
    return this.wrapperRenewToken(
      this.http
        .post<PaginatedModel<ReportWriterResult>>(
          `${this.endpoint}/reportResults`,
          {
            createdDateRangeStart: request.dateRangeStart.toISOString(),
            createdDateRangeEnd: request.dateRangeEnd.toISOString(),
            offset: request.offset,
            count: request.count,
            sorting: request.sorting
              ? [
                  {
                    column: request.sorting?.colId,
                    desc: request.sorting.sort === 'desc' ? true : false,
                  },
                ]
              : [],
            filter: request.filter,
          },
          {
            ...this.options,
            observe: 'response',
            params: {
              measurementPointId: request.measurementPointId,
            },
          }
        )
        .pipe(
          map((response: HttpResponse<any>): SubscriptionData => {
            return parseSubscriptionData(response.headers, response.body);
          })
        )
    );
  }

  deleteReportResult(reportId: number): Observable<StatusReturn> {
    return this.wrapperRenewToken(
      this.http.delete<StatusReturn>(`${this.endpoint}/reportResult`, {
        ...this.options,
        params: {
          reportResultId: reportId,
        },
      })
    );
  }

  createReportSchedule(
    mpId: number,
    reportWriterData: ReportWriterCreateRequest
  ): Observable<ReportWriterCreateResult> {
    return this.wrapperRenewToken(
      this.http.post<ReportWriterCreateResult>(`${this.endpoint}/reportSchedule`, reportWriterData, {
        ...this.options,
        params: {
          measurementPointId: mpId,
        },
      })
    );
  }

  editReportSchedule(id: number, report: EditReport): Observable<ReportScheduleId> {
    return this.wrapperRenewToken(
      this.http.put<ReportScheduleId>(
        `${this.endpoint}/reportSchedule/${id}`,
        { ...report },
        {
          ...this.options,
        }
      )
    );
  }

  getReportSetup(
    offset: number,
    count: number,
    mpId: number,
    filter: Array<ReportWriterFilter>,
    sorting: SortModelItem
  ): Observable<PaginatedModel<ReportWriterSetup>> {
    return this.wrapperRenewToken(
      this.http.post<PaginatedModel<ReportWriterSetup>>(
        `${this.endpoint}/reportSchedules`,
        {
          offset,
          count,
          sorting: sorting
            ? [
                {
                  column: sorting?.colId,
                  desc: sorting.sort === 'desc' ? true : false,
                },
              ]
            : [],
          filter,
        },
        {
          ...this.options,
          params: {
            measurementPointId: mpId,
          },
        }
      )
    );
  }

  disableReportSetup(
    reportSetupId: number,
    reportSetup: ReportWriterSetup,
    isDisable: boolean
  ): Observable<ReportScheduleId> {
    return this.wrapperRenewToken(
      this.http.patch<ReportScheduleId>(`${this.endpoint}/reportSchedule/disable/${reportSetupId}`, reportSetup, {
        ...this.options,
        params: {
          isDisable: isDisable,
        },
      })
    );
  }

  getReportTemplates(request: ReportWriterTemplatesRequest): Observable<ReportWriterTemplateResult[]> {
    return this.wrapperRenewToken(
      this.http.get<ReportWriterTemplateResult[]>(`${this.endpoint}/reportTemplates`, {
        ...this.options,
        params: {
          accountId: request.accountId,
          includeDefaultTemplates: request.includeDefaultTemplates,
        },
      })
    );
  }

  disableAllReports(mpId: number, isDisable: boolean): Observable<{ status: string }> {
    return this.wrapperRenewToken(
      this.http.patch<{ status: string }>(
        `${this.endpoint}/reportSchedule/disable`,
        {},
        {
          ...this.options,
          params: {
            measurementPointId: mpId,
            isDisable: isDisable,
          },
        }
      )
    );
  }

  deleteReportSetup(reportSetupId: number): Observable<{ status: string }> {
    return this.wrapperRenewToken(
      this.http.delete<{ status: string }>(`${this.endpoint}/reportSchedule/${reportSetupId}`, { ...this.options })
    );
  }

  runReport(reportScheduleId: number): Observable<ReportWriterResult> {
    return this.wrapperRenewToken(
      this.http.patch<ReportWriterResult>(
        `${this.endpoint}/reportSchedule/run/${reportScheduleId}`,
        {},
        { ...this.options }
      )
    );
  }

  runReportWithSSE(
    reportScheduleId: number,
    ctrl: AbortController,
    onMessage: (ev: EventSourceMessage) => void
  ): Promise<any> {
    return fetchEventSource(`${this.baseUrl}/sse/reportSchedule/run/${reportScheduleId}`, {
      method: 'GET',
      headers: {
        'Content-Type': this.options.headers.get('content-type'),
        Authorization: this.options.headers.get('Authorization'),
      },
      openWhenHidden: true,
      signal: ctrl.signal,
      onmessage: onMessage,
      onerror: (err) => {
        throw err;
      },
    });
  }

  updateLabelOnEvent(
    nature: string,
    eventId: number,
    label: string,
    isClear: boolean
  ): Observable<{ labelId: number }> {
    return this.wrapperRenewToken(
      this.http.patch<{ labelId: number }>(
        `${this.endpoint}/measurementPoint/alarmsEventsNote/label/${nature}/${eventId}`,
        {},
        {
          ...this.options,
          params: { label, isClear },
        }
      )
    );
  }

  updateCommentOnEvent(nature: string, eventId: number, comment: string): Observable<{ status: string }> {
    return this.wrapperRenewToken(
      this.http.patch<{ status: string }>(
        `${this.endpoint}/measurementPoint/alarmsEventsNote/comment/${nature}/${eventId}`,
        {},
        {
          ...this.options,
          params: { comment },
        }
      )
    );
  }

  getAssociatedLabelToAccount(accountId): Observable<Array<{ id: number; name: string }>> {
    return this.wrapperRenewToken(
      this.http.get<Array<{ id: number; name: string }>>(`${this.endpoint}/associatedLabelToAccount/${accountId}`, {
        ...this.options,
      })
    );
  }

  getRmsVariationAnalysisList(params: PaginationStringSort): Observable<PaginatedModel<RmsVariationAnalysis>> {
    return this.wrapperRenewToken(
      this.http.get<PaginatedModel<RmsVariationAnalysis>>(`${this.endpoint}/rmsVariationAnalysis/list/`, {
        ...this.options,
        params: { offset: params.offset, count: params.count, sorting: params.sorting },
      })
    );
  }

  getRmsVariationAnalysis(analysisId: number): Observable<RmsVariationAnalysis> {
    return this.wrapperRenewToken(
      this.http.get<RmsVariationAnalysis>(`${this.endpoint}/rmsVariationAnalysis/${analysisId}`, {
        ...this.options,
      })
    );
  }

  deleteRmsVariationAnalysis(analysisId: number): Observable<{ status: string }> {
    return this.wrapperRenewToken(
      this.http.delete<{ status: string }>(`${this.endpoint}/rmsVariationAnalysis/${analysisId}`, { ...this.options })
    );
  }

  postRmsVariationAnalysis(
    accountId: number,
    analysisParams: CreateRmsVariationAnalysisParams
  ): Observable<CreateRmsVariationAnalysisResponse> {
    return this.wrapperRenewToken(
      this.http.post<CreateRmsVariationAnalysisResponse>(`${this.endpoint}/rmsVariationAnalysis`, analysisParams, {
        ...this.options,
        params: {
          accountId: accountId,
        },
      })
    );
  }

  putRmsVariationAnalysis(
    rmsVariationAnalysisId: number,
    accountId: number,
    analysisParams: CreateRmsVariationAnalysisParams
  ): Observable<UpdateRmsVariationAnalysisResponse> {
    return this.wrapperRenewToken(
      this.http.put<UpdateRmsVariationAnalysisResponse>(
        `${this.endpoint}/rmsVariationAnalysis/${rmsVariationAnalysisId}`,
        analysisParams,
        {
          ...this.options,
          params: {
            accountId: accountId,
          },
        }
      )
    );
  }

  getRmsVariationAnalysisEventMagnitude(analysisId: number): Observable<EventMagnitudeDist> {
    return this.wrapperRenewToken(
      this.http.get<EventMagnitudeDist>(`${this.endpoint}/rmsVariationAnalysis/${analysisId}/eventMagnitudeDist`, {
        ...this.options,
      })
    );
  }

  getRmsVariationAnalysisEventDuration(analysisId: number): Observable<EventDurationDist> {
    return this.wrapperRenewToken(
      this.http.get<EventDurationDist>(`${this.endpoint}/rmsVariationAnalysis/${analysisId}/eventDurationDist`, {
        ...this.options,
      })
    );
  }

  getRmsVariationAnalysisIticChartPlot(analysisId: number): Observable<IticChartPlot[]> {
    return this.wrapperRenewToken(
      this.http.get<IticChartPlot[]>(`${this.endpoint}/rmsVariationAnalysis/${analysisId}/iticChartPlot`, {
        ...this.options,
      })
    );
  }

  getRmsVariationAnalysisEvents(analysisId: number): Observable<RmsVariationEvent[]> {
    return this.wrapperRenewToken(
      this.http.get<RmsVariationEvent[]>(`${this.endpoint}/rmsVariationAnalysis/${analysisId}/events`, {
        ...this.options,
      })
    );
  }

  createNotificationGroup(accountId: number, group: CreateGroupParams): Observable<NotificationGroupId> {
    return this.wrapperRenewToken(
      this.http.post<NotificationGroupId>(`${this.endpoint}/notificationGroup`, group, {
        ...this.options,
        params: {
          accountId: accountId,
        },
      })
    );
  }

  editNotificationGroup(notificationGroupId: number, group: CreateGroupParams): Observable<NotificationGroupId> {
    return this.wrapperRenewToken(
      this.http.put<NotificationGroupId>(
        `${this.endpoint}/notificationGroup/${notificationGroupId}`,
        { ...group },
        {
          ...this.options,
        }
      )
    );
  }

  getNotificationGroupList(params: PaginationStringSort): Observable<PaginatedModel<NotificationGroupList>> {
    return this.wrapperRenewToken(
      this.http.get<PaginatedModel<NotificationGroupList>>(`${this.endpoint}/notificationGroup`, {
        ...this.options,
        params: { offset: params.offset, count: params.count, sortings: params.sorting },
      })
    );
  }

  getNotificationGroup(groupId: number): Observable<NotificationGroup> {
    return this.wrapperRenewToken(
      this.http.get<NotificationGroup>(`${this.endpoint}/notificationGroup/${groupId}`, { ...this.options })
    );
  }

  enableGroup(group: NotificationGroup, isDisabled: boolean): Observable<NotificationGroupId> {
    return this.wrapperRenewToken(
      this.http.patch<NotificationGroupId>(
        `${this.endpoint}/notificationGroup/disable/${group.notificationGroupId}`,
        group,
        {
          ...this.options,
          params: { isDisabled: isDisabled },
        }
      )
    );
  }

  deleteGroup(groupId: number): Observable<{ status: string }> {
    return this.wrapperRenewToken(
      this.http.delete<{ status: string }>(`${this.endpoint}/notificationGroup/${groupId}`, { ...this.options })
    );
  }

  makeAccountPrimary(accountId: number, userId: number, isPrimary: boolean): Observable<{}> {
    return this.wrapperRenewToken(
      this.http.put(
        `${this.endpoint}/makeAccountPrimary/${accountId}/${userId}`,
        {
          isPrimary,
        },
        { ...this.options }
      )
    );
  }

  getMaxLoadCurrentDemand(mpId: number, reportStartDate?: string): Observable<MaxLoadCurrentDemand> {
    let params = {};
    if (reportStartDate) {
      params = { referenceDateTime: reportStartDate };
    }
    return this.wrapperRenewToken(
      this.http.get<MaxLoadCurrentDemand>(`${this.endpoint}/trends/maxLoadCurrentDemand/measurementPoint/${mpId}`, {
        ...this.options,
        params: params,
      })
    );
  }

  getEligibleEventList(): Observable<Array<NotificationEventGroup>> {
    return this.wrapperRenewToken(
      this.http.get<Array<NotificationEventGroup>>(`${this.endpoint}/notificationGroup/eventList`, {
        ...this.options,
        params: { groupByType: true, sortByGroup: true },
      })
    );
  }

  getHeartbeat(mpId: number, limit: number = 1): Observable<Array<Heartbeat>> {
    return this.wrapperRenewToken(
      this.http.get<Array<Heartbeat>>(`${this.endpoint}/measurementPoint/heartbeat/${mpId}`, {
        ...this.options,
        params: {
          limit: limit,
        },
      })
    );
  }

  requestToUpgradeSubscription(accountId: number): Observable<{ message: string }> {
    return this.wrapperRenewToken(
      this.http.post<{ message: string }>(`${this.endpoint}/account/${accountId}/requestToUpgrade`, null, {
        ...this.options,
      })
    );
  }
}
