<section>
  <div class="title">
    @if (settings.material_ligature === 'decagram') {
    <mat-icon class="premium-icon" svgIcon="decagram"></mat-icon>
    }
    <h1 mat-dialog-title [innerHTML]="settings.title"></h1>
  </div>
  <hr class="mt-3" />
  <div mat-dialog-content>
    @if (settings.showRequestUpgrade && settings.accountId && requestToUpgradeSuccess === null && (isAdmin$ | async) ===
    true) {
    <button mat-raised-button color="accent" class="request-upgrade" (click)="requestUpgrade()">
      {{ 'subscription.requestUpgrade.title' | translate }}
    </button>
    } @else if(settings.showRequestUpgrade && settings.accountId && requestToUpgradeSuccess === true && (isAdmin$ |
    async) === true) {
    {{ 'subscription.requestUpgrade.successMessage' | translate }}<br />
    } @else if(settings.showRequestUpgrade && settings.accountId && requestToUpgradeSuccess === false && (isAdmin$ |
    async) === true) {
    {{ 'subscription.requestUpgrade.failureMessage' | translate }}<br />
    }
    <p [innerHTML]="settings.message"></p>
  </div>
  <div mat-dialog-actions>
    @if (settings.closePermanentlyCheckbox) {
    <mat-checkbox (change)="onCheckboxChange($event)">Don’t show this message again</mat-checkbox>
    } @for (btn of settings.buttons; track btn; let i = $index) {
    <button
      mat-raised-button
      data-cy="notifications-button"
      class="notifications-button"
      (click)="btn.callback()"
      color="{{ btn.color }}"
      [ngClass]="{ transparent: (i + 1) % 2 }"
    >
      {{ btn.caption }}
    </button>
    }
  </div>
</section>
