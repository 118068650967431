import { cloneDeep } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { LoadsActions } from 'src/app/_store/_loads/actions';
import { MaintenanceActions } from 'src/app/_store/_maintenance/actions';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { Router } from '@angular/router';
import * as moment from 'moment';

import { AuthService, DiligentApiService } from 'src/app/_shared/services';
import { UserActions } from 'src/app/_store/_user/actions';
import { InitActions } from 'src/app/_store/_init/actions';
import { HierarchyActions } from 'src/app/_store/_hierarchy/actions';
import { ChannelsActions } from 'src/app/_store/_channels/actions';
import { QubescanDashboardActions } from 'src/app/_store/_qubescan-dashboard/actions';
import * as fromStore from 'src/app/_store/_reducers';
import { MeasurementPointsService } from 'src/app/_shared/services/measurement-points.service';
import { MeasurementPoint } from 'src/app/_shared/classes/MeasurementPoint';
import { TrendAlarmModelService } from 'src/app/_shared/services/trend-alarm-model.service';
import { ChartsActions } from 'src/app/_store/_charts/actions';
import { NotificationsService } from 'src/app/_shared/modules/notifications/shared/notifications.service';
import { RolesActions } from 'src/app/_store/_roles/actions';
import { RangeSelectorActions } from 'src/app/_store/_range-selector/actions';
import { FeaturesActions } from 'src/app/_store/_features/actions';
import { urlToUrlParams } from 'src/app/_shared/helpers/url-params-builder';

@Injectable()
export class InitEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InitActions.init.type),
      switchMap((action) => {
        return forkJoin([
          this.authService.getAccountStore(action.payload.accountId),
          this.authService.getUserPreferences(),
          this.diligentApiService.getUserById(action.payload.id, action.payload.accountId),
        ]).pipe(
          switchMap(([account, userPreferences, user]) => {
            // Set default locale to English and replace locale date/time format with the browser
            const localMoment = moment();
            localMoment.locale(navigator.language);
            moment.updateLocale('en', {
              longDateFormat: {
                LT: localMoment.creationData().locale.longDateFormat('LT'),
                LTS: localMoment.creationData().locale.longDateFormat('LTS'),
                L: localMoment.creationData().locale.longDateFormat('L'),
                LL: localMoment.creationData().locale.longDateFormat('LL'),
                LLL: localMoment.creationData().locale.longDateFormat('LLL'),
                LLLL: localMoment.creationData().locale.longDateFormat('LLLL'),
              },
            });

            if (Object.keys(userPreferences).length === 0) {
              const hintEnabled = user.name !== 'Viewer';
              userPreferences = {
                hint: {
                  createMp: hintEnabled,
                  associate: hintEnabled,
                  liveMeter: hintEnabled,
                  sagDirection: hintEnabled,
                  notificationCenter: hintEnabled,
                  firmwareUpdate: hintEnabled,
                  firmwareAndNotification: hintEnabled,
                  rangeSelection: hintEnabled,
                },
                latestNewsId: -1,
                welcomeMessage: true,
              };
            } else if (userPreferences.latestNewsId === undefined || userPreferences.latestNewsId === -1) {
              userPreferences = {
                ...userPreferences,
                latestNewsId: 0,
              };
            }

            let getMpParams = userPreferences.mpId;
            let updatePrefs = false;
            if (action.payload.urlParams?.mpId && userPreferences.mpId !== action.payload.urlParams.mpId) {
              updatePrefs = true;
              getMpParams = +action.payload.urlParams.mpId;
              userPreferences = {
                ...userPreferences,
                mpId: getMpParams,
              };
            }

            return [
              FeaturesActions.initFeatures(),
              UserActions.getMp({
                payload: { mpId: getMpParams, returnUrl: action.payload.returnUrl },
              }),
              UserActions.setUserPreferences({ payload: { userPreferences: cloneDeep(userPreferences) } }),
              UserActions.getUserSuccess({ user: user }),
              UserActions.setIsSysAdminSuccess({
                payload: { isSysAdmin: user.isSystemAdministrator === 1 },
              }),
              HierarchyActions.getHierarchyUser(),
              UserActions.initUser({
                payload: {
                  accountId: action.payload.accountId,
                  getMpParams: getMpParams,
                  isLogin: action.payload.isLogin,
                },
              }),
              UserActions.getAccountSuccess({ payload: { account: account } }),
              UserActions.setUserIsPartnerSuccess({
                payload: { isPartner: account.isPartner },
              }),
              RolesActions.getRoles(),
              UserActions.initViewerMode(),
              updatePrefs
                ? UserActions.updateUserPreferences({
                    payload: { property: { mpId: getMpParams } },
                  })
                : UserActions.emptyActions(),
            ];
          }),
          catchError((err) => {
            if (this.apiRetryCount < 3) {
              this.store.dispatch(
                InitActions.init({
                  payload: {
                    id: action.payload.id,
                    accountId: action.payload.accountId,
                    returnUrl: action.payload.returnUrl,
                    isLogin: action.payload.isLogin,
                    urlParams: action.payload.urlParams,
                  },
                })
              );
              this.apiRetryCount++;
            } else {
              this.apiRetryCount = 0;
              this.store.dispatch(UserActions.toogleLoginLoading({ payload: { loginLoading: false } }));
              this.notificationsService.alert(this.translateService.instant('global.errors.tryAgain'));
            }
            throw err;
          })
        );
      })
    )
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InitActions.login.type),
      map((action) => {
        if (action.payload.returnUrl) {
          this.router.navigateByUrl(action.payload.returnUrl);
        } else {
          this.router.navigate(['fleet']);
        }
        return InitActions.loginSuccess();
      })
    )
  );

  changeSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InitActions.changeSelection.type),
      withLatestFrom(this.store.select(fromStore.getUserPreferences)),
      switchMap(([action, userPref]) => {
        userPref = {
          ...userPref,
          account: action.payload.point.accountId,
          mpId: action.payload.point.measurementPointId,
          onBoarding: {
            isOnBoarding: action.payload.onBoarding.isOnBoarding,
            timestamp: action.payload.onBoarding.timestamp,
          },
        };
        this.authService.savePreferences(userPref, Object.keys(userPref).length > 0);
        return forkJoin([
          this.mpService.getChannelDefinition(
            action.payload.point.measurementPointId.toString(),
            'trend',
            action.payload.point.measurementPointTypeId
          ),
          this.mpService.getChannelDefinition(
            action.payload.point.measurementPointId.toString(),
            'live',
            action.payload.point.measurementPointTypeId
          ),
          this.trendAlarmModelService.getTrendAlarmChannelList(action.payload.point.measurementPointId),
          this.mpService.getKPI(action.payload.point.measurementPointId),
          of(userPref),
          action.payload.point.details === undefined
            ? this.mpService.getMeasurementPoint(action.payload.point.measurementPointId)
            : of(action.payload.point),
        ]).pipe(
          switchMap(([channelDef, channelMeterDef, channel, kpi, userPref, mp]) => {
            if (action.payload.point.details === undefined) {
              mp.measurementPointId = mp.roomId;
            }
            return [
              UserActions.setQubescanTypeModel({
                payload: {
                  type: channelMeterDef.powerConfiguration ? channelMeterDef.powerConfiguration : null,
                  model: channelMeterDef.pqubeModel ? channelMeterDef.pqubeModel : null,
                },
              }),
              LoadsActions.updateLoads({ payload: { loads: [0] } }),
              UserActions.setHeaderName({
                payload: {
                  accountName: mp.accountName,
                  site: mp.locationName,
                  mpName: mp.mpId,
                  timezone: mp.timezone,
                },
              }),
              MaintenanceActions.getMaintenanceRequests({ payload: { mpId: userPref.mpId } }),
              UserActions.setFirmwareVersionSuccess({
                payload: { firmwareVersion: mp.firmwareVersion },
              }),
              ChannelsActions.setMeterChannelsSuccess({
                payload: {
                  meterChannels: channelMeterDef.channels,
                },
              }),
              UserActions.setAlarmChannelSuccess({ payload: { channel: channel } }),
              QubescanDashboardActions.getKPISuccess({ payload: { kpi: kpi } }),
              QubescanDashboardActions.setValueSucces({
                payload: { qubescanValue: null },
              }),
              ChannelsActions.setTrendsChannelsSuccess({
                payload: {
                  trendsChannels: channelDef.channels,
                  mp: new MeasurementPoint(mp),
                  returnUrl:
                    action.payload.redirect +
                    '?account=' +
                    action.payload.point.accountId +
                    '&mpId=' +
                    action.payload.point.measurementPointId,
                },
              }),
              ChartsActions.initCharts({
                payload: {
                  startDate: action.payload.customDate,
                  scope: 'day',
                  timezone: action.payload.point.timezone,
                  granularity: 'minute',
                  interval: 1,
                  commissionedWhen: action.payload.point.commissionedWhen,
                  usePreCommissionStart: userPref.usePreCommissionStart ? userPref.usePreCommissionStart : false,
                  forceUpdate: action.payload.forceChartUpdate,
                },
              }),
              RangeSelectorActions.initRangeSelectorGlobal({
                payload: {
                  timezone: mp.timezone,
                  commissionedWhen: mp.commissionedWhen,
                  usePreCommissionStart: userPref?.usePreCommissionStart ? userPref.usePreCommissionStart : false,
                },
              }),
              MaintenanceActions.setIsAvailable({ payload: true }),
            ];
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions<InitActions.InitActionsUnion>,
    private authService: AuthService,
    private mpService: MeasurementPointsService,
    private trendAlarmModelService: TrendAlarmModelService,
    private router: Router,
    private store: Store<fromStore.State>,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private diligentApiService: DiligentApiService
  ) {}

  private apiRetryCount = 0;
}
