import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';

import { RangeSelectorActions } from 'src/app/_store/_range-selector/actions';
import * as fromStore from 'src/app/_store/_reducers';

@Injectable()
export class RangeSelectorEffects {
  setScope$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RangeSelectorActions.setScope.type),
      map((action) =>
        RangeSelectorActions.setInterval({
          payload: {
            rangeType: action.payload.rangeType,
          },
        })
      )
    )
  );

  changeType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RangeSelectorActions.changeType.type),
      withLatestFrom(this.store.select(fromStore.getRangeSelector)),
      switchMap(([action, rangeSelector]) => {
        if (action.payload.type === 'period') {
          let start,
            end,
            effect = [];
          if (rangeSelector[action.payload.rangeType].startDatePeriod === null) {
            start = moment().tz(action.payload.timezone).startOf('days').format('L');
            end = moment().tz(action.payload.timezone).endOf('days').format('L');
          } else {
            start = moment(rangeSelector[action.payload.rangeType].startDateRange, 'L LT');
            end = moment(rangeSelector[action.payload.rangeType].endDateRange, 'L LT');
            let test = end.diff(start, 'days');

            if (test === 0) {
              start = moment(rangeSelector[action.payload.rangeType].startDateRange, 'L LT').format('L');
              end = moment(rangeSelector[action.payload.rangeType].endDateRange, 'L LT').format('L');
              if (rangeSelector[action.payload.rangeType].scope !== 'days') {
                effect.push(
                  RangeSelectorActions.setScope({
                    payload: {
                      rangeType: action.payload.rangeType,
                      scope: 'days',
                    },
                  })
                );
              }
            } else if (test > 0 && test < 8) {
              start = moment(rangeSelector[action.payload.rangeType].startDateRange, 'L LT')
                .startOf('weeks')
                .format('L');
              end = moment(start, 'L').endOf('weeks').format('L');
              if (rangeSelector[action.payload.rangeType].scope !== 'weeks') {
                effect.push(
                  RangeSelectorActions.setScope({
                    payload: {
                      rangeType: action.payload.rangeType,
                      scope: 'weeks',
                    },
                  })
                );
              }
            } else if (test > 7 && test < 91) {
              start = moment(rangeSelector[action.payload.rangeType].startDateRange, 'L LT')
                .startOf('months')
                .format('L');
              end = moment(start, 'L').endOf('months').format('L');
              if (rangeSelector[action.payload.rangeType].scope !== 'months') {
                effect.push(
                  RangeSelectorActions.setScope({
                    payload: {
                      rangeType: action.payload.rangeType,
                      scope: 'months',
                    },
                  })
                );
              }
            } else if (test > 90 && test < 365) {
              start = moment(rangeSelector[action.payload.rangeType].startDateRange, 'L LT').startOf('years');
              end = moment(start, 'L').endOf('years');
              if (rangeSelector[action.payload.rangeType].scope !== 'years') {
                effect.push(
                  RangeSelectorActions.setScope({
                    payload: {
                      rangeType: action.payload.rangeType,
                      scope: 'years',
                    },
                  })
                );
              }
            }
          }

          let title = this.setTitle(rangeSelector[action.payload.rangeType].scope, start);

          return [
            ...effect,
            RangeSelectorActions.setRangeSelector({
              payload: {
                rangeType: action.payload.rangeType,
                type: action.payload.type,
                startDatePeriod: start ? start : rangeSelector[action.payload.rangeType].startDatePeriod,
                endDatePeriod: end ? end : rangeSelector[action.payload.rangeType].endDatePeriod,
                title: title,
              },
            }),
          ];
        } else if (action.payload.type === 'range') {
          return [
            RangeSelectorActions.setRangeSelector({
              payload: {
                rangeType: action.payload.rangeType,
                type: action.payload.type,
                startDateRange: moment(rangeSelector[action.payload.rangeType].startDatePeriod, 'L').format('L LT'),
                endDateRange: moment(rangeSelector[action.payload.rangeType].endDatePeriod + ' 23:59', 'L LT').format(
                  'L LT'
                ),
                title:
                  moment(rangeSelector[action.payload.rangeType].startDatePeriod, 'L LT').format('L') +
                  ' ' +
                  moment(rangeSelector[action.payload.rangeType].startDatePeriod, 'L LT').format('LT') +
                  ' > ' +
                  moment(rangeSelector[action.payload.rangeType].endDatePeriod, 'L LT').format('L') +
                  ' ' +
                  moment(rangeSelector[action.payload.rangeType].endDatePeriod + ' 23:59', 'L LT').format('LT'),
              },
            }),
          ];
        }
      })
    )
  );

  setInterval$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RangeSelectorActions.setInterval.type),
      withLatestFrom(this.store.select(fromStore.getRangeSelector)),
      switchMap(([action, rangeSelector]) => {
        let end;
        let start;
        const endDatePeriod = moment(rangeSelector[action.payload.rangeType].endDatePeriod, 'L');
        const startOfToday = moment().startOf('day');
        if (endDatePeriod.isAfter(startOfToday) || endDatePeriod.isSame(startOfToday)) {
          end = moment().endOf(rangeSelector[action.payload.rangeType].scope);
          start = moment().startOf(rangeSelector[action.payload.rangeType].scope);
        } else {
          end = moment(rangeSelector[action.payload.rangeType].startDatePeriod, 'L').endOf(
            rangeSelector[action.payload.rangeType].scope
          );
          start = moment(rangeSelector[action.payload.rangeType].startDatePeriod, 'L').startOf(
            rangeSelector[action.payload.rangeType].scope
          );
        }
        return [
          RangeSelectorActions.setIntervalSuccess({
            payload: {
              rangeType: action.payload.rangeType,
              startDate: moment(start).format('L'),
              endDate: moment(end).format('L'),
            },
          }),
        ];
      })
    )
  );

  changeInterval$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RangeSelectorActions.changeInterval.type),
      map((action) => action.payload),
      withLatestFrom(this.store.select(fromStore.getRangeSelector)),
      switchMap(([action, rangeSelector]) => {
        let end;
        let start;

        if (action.direction === 'past') {
          start = moment(rangeSelector[action.rangeType].startDatePeriod, 'L').subtract(
            1,
            rangeSelector[action.rangeType].scope
          );
          end = moment(start).endOf(rangeSelector[action.rangeType].scope);
        } else if (action.direction === 'future') {
          start = moment(rangeSelector[action.rangeType].startDatePeriod, 'L').add(
            1,
            rangeSelector[action.rangeType].scope
          );
          end = moment(start).endOf(rangeSelector[action.rangeType].scope);
        }

        return [
          RangeSelectorActions.setIntervalSuccess({
            payload: {
              rangeType: action.rangeType,
              startDate: start.format('L'),
              endDate: end.format('L'),
            },
          }),
        ];
      })
    )
  );

  setYear$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RangeSelectorActions.setYear.type),
      map((action) => action.payload),
      withLatestFrom(this.store.select(fromStore.getMp)),
      switchMap(([action, mp]) => {
        const newDate = moment()
          .tz(mp.timezone)
          .year(action.year + 1)
          .month(0)
          .date(1)
          .startOf('day')
          .subtract(30, 'minute');

        return [
          RangeSelectorActions.setIntervalSuccess({
            payload: {
              rangeType: action.rangeType,
              startDate: moment(newDate.startOf('year')).tz(mp.timezone).format('L'),
              endDate: moment(newDate.endOf('year')).tz(mp.timezone).format('L'),
            },
          }),
        ];
      })
    )
  );

  setMonth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RangeSelectorActions.setMonth.type),
      map((action) => action.payload),
      withLatestFrom(this.store.select(fromStore.getMp)),
      switchMap(([action, mp]) => {
        const newDate = moment().tz(mp.timezone).year(action.year).month(action.month).date(1);

        return [
          RangeSelectorActions.setIntervalSuccess({
            payload: {
              rangeType: action.rangeType,
              startDate: moment(newDate.startOf('month')).tz(mp.timezone).format('L'),
              endDate: moment(newDate.endOf('month')).tz(mp.timezone).format('L'),
            },
          }),
        ];
      })
    )
  );

  setWeekDay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RangeSelectorActions.setWeekDay.type),
      withLatestFrom(this.store.select(fromStore.getMp), this.store.select(fromStore.getRangeSelector)),
      switchMap(([action, mp, rangeSelector]) => {
        const newDate = moment()
          .tz(mp.timezone)
          .year(action.payload.year)
          .month(action.payload.month)
          .date(action.payload.day);

        return [
          RangeSelectorActions.setIntervalSuccess({
            payload: {
              rangeType: action.payload.rangeType,
              startDate: moment(newDate.startOf(rangeSelector[action.payload.rangeType].scope)).format('L'),
              endDate: moment(newDate.endOf(rangeSelector[action.payload.rangeType].scope)).format('L'),
            },
          }),
        ];
      })
    )
  );

  setIntervalSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RangeSelectorActions.setIntervalSuccess.type),
      map((actions) => actions.payload),
      withLatestFrom(this.store.select(fromStore.getRangeSelector)),
      switchMap(([action, rangeSelector]) => {
        let title = this.setTitle(rangeSelector[action.rangeType].scope, action.startDate);

        return [RangeSelectorActions.setTitleSuccess({ payload: { rangeType: action.rangeType, title: title } })];
      })
    )
  );

  setPrecommissionStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RangeSelectorActions.setPrecommissionStart.type),
      map((action) => RangeSelectorActions.setInterval({ payload: { rangeType: action.payload.rangeType } }))
    )
  );

  constructor(
    private actions$: Actions<RangeSelectorActions.RangeSelectorActionsUnion>,
    private store: Store<fromStore.State>
  ) {}

  setTitle(scope: string, startDate): string {
    let title;
    if (scope === 'days') {
      title = moment(startDate, 'L').format('dddd LL');
    } else if (scope === 'weeks') {
      title =
        moment(startDate, 'L').startOf('week').format('LL') + ' - ' + moment(startDate, 'L').endOf('week').format('LL');
    } else if (scope === 'months') {
      title = moment(startDate, 'L').format('MMMM YYYY');
    } else if (scope === 'years') {
      title = moment(startDate, 'L').format('YYYY');
    }
    return title;
  }
}
