import { switchMap, take, takeUntil } from 'rxjs/operators';
import { Subject, forkJoin, interval, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromStore from 'src/app/_store/_reducers';
import { AuthService } from 'src/app/_shared/services';
import { InitActions } from 'src/app/_store/_init/actions';
import { Maintenance } from 'src/environments/temporary-maintenance';
import { NewsActions } from 'src/app/_store/_news/actions';
import { urlToUrlParams } from 'src/app/_shared/helpers/url-params-builder';

@Injectable()
export class InitProvider {
  private readonly timeout = 300; //60 seconds max timeout
  constructor(private store: Store<fromStore.State>, private authService: AuthService) {}

  load(): Promise<boolean> {
    if (!Maintenance) {
      const url = new URL(window.location.href);
      const mpId = url.searchParams.get('mpId');
      const account = url.searchParams.get('account');
      const pathName = url.pathname;
      return new Promise((resolve, reject) => {
        this.authService.authed.pipe(take(1)).subscribe((payload) => {
          if (payload) {
            const urlParams = urlToUrlParams(pathName, { mpId: mpId, account: account });
            this.store.dispatch(NewsActions.initNews());
            this.store.dispatch(
              InitActions.init({
                payload: {
                  id: payload.id,
                  accountId: payload.accountId,
                  returnUrl: pathName,
                  isLogin: true,
                  urlParams: urlParams,
                },
              })
            );

            const stopTimer: Subject<any> = new Subject();
            const criticalStoreObservable = [
              this.store.select(fromStore.getUser).pipe(take(1)),
              this.store.select(fromStore.getUserPreferences).pipe(take(1)),
            ];
            if (urlParams.mpId) {
              criticalStoreObservable.push(
                ...[
                  this.store.select(fromStore.getMp).pipe(take(1)),
                  this.store.select(fromStore.getAlarmChannel).pipe(take(1)),
                  this.store.select(fromStore.getTrendsChannels).pipe(take(1)),
                  this.store.select(fromStore.getMeterChannels).pipe(take(1)),
                ]
              );
            }
            interval(200)
              .pipe(
                takeUntil(stopTimer),
                switchMap((timer) => {
                  return forkJoin([
                    of(timer),
                    //Critical store needed for the app to work correctly
                    ...criticalStoreObservable,
                  ]);
                })
              )
              .subscribe(([timer, ...params]) => {
                if (params.every((param) => param)) {
                  stopTimer.next(true);
                  resolve(true);
                } else if (timer >= this.timeout) {
                  stopTimer.next(true);
                  alert('An error has occurred: press ok to reload the page.');
                  this.authService.resetToken();
                  window.location.reload();
                  reject();
                }
              });
          } else {
            resolve(true);
          }
        });
      });
    }
  }
}
